<template>
  <div
    :style="currentState === STATES.loading ? null : featurePosition.style.value"
    :class="{'absolute-cover flex-center': currentState === STATES.loading}"
  >

    <core-busy-dots v-if="currentState === STATES.loading" />

    <!--is it playable-->
    <core-media-player
      v-else-if="currentState === STATES.playable"
      class="f-file_detail_video-video"
      :src="videoSrc"
      :poster-src="posterSrc"
      :thumbnail-src="thumbnailSrc"
      :screen-caps-count="file.screen_caps_count"
      :name="file.name"
      :width="featurePosition.style.value.width"
      :height="featurePosition.style.value.height"
      :is-active="isActive && !isTransitioning"
      ref="video-player"
      @error="onVideoError"
      @full-screen-change="onFullScreenChange"
    />

    <!--otherwise (processing, errored, need plan)-->
    <file-detail-non-viewable v-else-if="showNonPlayableState" class="f-file_detail_video-unplayable">

      <template #image>
        <div
          :class="{
            'cursor-pointer': currentState.action
          }"
          @click="currentState.action"
        >
          <!--if need plan we want to display screen cap thumb here-->
          <div v-if="currentState.showThumb" class="relative my-0 mx-auto w-24 sm:w-44 md:w-64">
            <img :src="thumbnailSrc" class="border-white border-2 rounded">
            <u-icon :name="currentState.icon" class="text-5xl absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-white/50 py-px px-1.5 rounded-full" />
          </div>

          <!--otherwise, we just display icon-->
          <div v-else>
            <u-icon :name="currentState.icon" class="text-5xl text-white" :class="currentState.spin ? 'animate-spin' : null" />
          </div>
        </div>
      </template>

      <template #description>
        <a v-if="currentState.action" href="#" @click.prevent="currentState.action">
          <h3>{{currentState.title}}</h3>
        </a>
        <h3 v-else>{{currentState.title}}</h3>

        <p v-if="file.user">{{currentState.desc}}</p>
        <p v-if="currentState.showDownload">
          You can always <a href="#" @click.prevent.stop="downloadVideo">download this video</a> and watch it on your computer.
        </p>
      </template>

    </file-detail-non-viewable>

  </div>
</template>

<script setup>
  const fileContext = inject('fileContext');
  const emit = defineEmits(['full-screen-change', 'download']);
  const props = defineProps({
    file: Object,
    availableSpace: Object,
    isActive: Boolean,
    isTransitioning: Boolean
  });

  const filesStore = useFilesStore();

  const STATES = {
    loading: 'LOADING',
    playable: 'PLAYABLE',
    errored: {
      icon: 'i-ri-error-warning-fill',
      title: 'Playback Unavailable',
      desc: 'Playback is unavailable because something went wrong while processing this file. Your original file is safe and can be downloaded from the link above.'
    },
    playbackErrored: {
      icon: 'i-ri-error-warning-fill',
      title: 'Playback Error',
      desc: 'Something went wrong and we could not play your video. Please try again later.'
    },
    needsTranscoding: 'needsTranscoding',
    processing: {
      spin: true,
      icon: COMMON_ICONS.loading,
      title: 'Video Processing',
      desc: `Hang tight! We're getting your video ready for its first playback. It'll just take a moment.`,
    },
    needsPlan: {
      icon: 'i-ri-play-circle-line',
      showThumb: true,
      title: 'Upgrade to Play Video',
      desc: 'FOREVER® Video Streaming is required to watch videos directly in your account.',
      showDownload: true,
      action: () => (window.location = EXTERNAL_URLS.video)
    },
    unavailable: {
      icon: 'i-ri-play-circle-line',
      showThumb: true,
      title: 'Playback Unavailable',
      desc: 'Playback is unavailable for this video.',
      showDownload: true
    }
  };

  const isPlaybackErrored = ref(false);
  const videoSrc = computed(() => props.file.preview_url || props.file.download_items[0].path);
  const posterSrc = computed(() => updateQueryParameters(props.file.images.preview, {rotate: null}));
  const thumbnailSrc = computed(() => updateQueryParameters(props.file.images.preview, {
    height: 512,
    width: 512,
    crop: 'fit'
  }));

  const userContext = useUserContext({entity: props.file});

  const currentState = computed(() => {
    //if errored, same state for all users
    if (props.file.is.errored) {
      return STATES.errored;
    }

    if (isPlaybackErrored.value) {
      return STATES.playbackErrored;
    }

    //processing, display for all users
    if (props.file.is.processing) {
      return STATES.processing;
    }

    //if streamable play for all users
    if (props.file.is.streamable) {
      return STATES.playable;
    }

    //if we do not have the file detail record
    if (!props.file.is.detail) {
      return STATES.loading;
    }

    //transcode, display for all users
    if (props.file.user.abilities?.includes('transcode_video')) {
      return STATES.needsTranscoding;
    }

    //if owner, needs a plan
    if (userContext.isEntityOwner.value) {
      return STATES.needsPlan;
    }

    //shared, unavailable
    return STATES.unavailable;
  });

  const showNonPlayableState = computed(() => typeof currentState.value === 'object');

  const featurePosition = useDetailFeaturePosition({
    featureDimensions: computed(() => {
      if (currentState.value === STATES.playable) {
        return {
          width: Math.max(props.file.width, VIDEO_MIN_WIDTH),
          height: Math.max(props.file.height, VIDEO_MIN_HEIGHT)
        };
      }

      return {
        width: props.availableSpace.width,
        height: props.availableSpace.height
      };
    }),
    availableSpace: computed(() => props.availableSpace)
  });

  async function convert() {
    const params = {
      fileId: props.file.id,
      shareToken: fileContext.shareToken
    };

    switch (fileContext.contextType) {
      case 'album':
        params.albumId = fileContext.contextId;
        break;
      case 'tag':
        params.tagId = fileContext.contextId;
        break;
      case 'container':
        params.containerId = fileContext.contextId;
        break;
    }

    await useFileApi().transcodeVideoFile(params);

    //re-fetch the file
    await filesStore.getFileDetails({files: [props.file], refresh: true, ...fileContext});
  }

  function onVideoError() {
    isPlaybackErrored.value = true;
  }
  function onFullScreenChange(inFullScreen) {
    emit('full-screen-change', inFullScreen);
  }

  function downloadVideo() {
    emit('download', props.file);
  }

  watch(currentState, newVal => {
    //auto trigger convert if the video needs to be transcoded
    if (newVal === STATES.needsTranscoding) {
      convert();
    }
  });

</script>

<style scoped lang="scss">
  :deep(.f-file_detail_video-video .img) {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
</style>
