<template>
  <div @click="emit('click')" class="absolute z-10 opacity-0 sm:group-hover:opacity-40 hover:!opacity-100 p-7 top-1/2 -translate-y-1/2 cursor-pointer transition-opacity duration-300" :class="isNext ? 'right-0 justify-end' : 'left-0 justify-start'">
    <button class="w-14 h-14 bg-charcoal/60 flex-center rounded-full">
      <u-icon :name="icon" class="text-white text-4xl" />
    </button>
  </div>
</template>

<script setup>
  const props = defineProps({
    isNext: Boolean
  });

  const emit = defineEmits(['click']);

  const icon = props.isNext ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line';
</script>
