<template>
  <div class="p-2 w-64 max-w-64">

    <p class="break-long-string text-white font-semibold text-sm">{{file.name}}</p>

    <u-divider class="my-2" />

    <div class="flex text-sm justify-between text-gray-200" v-if="file.is.photo || file.is.video || file.is.audio || file.is.document">
      <span>{{$dayjs(file.taken_at).utc().format('LL')}}</span>
      <span>{{$dayjs(file.taken_at).utc().format('h:mm A')}}</span>
    </div>

    <div class="flex text-sm justify-between text-gray-200">
      <span>{{file.type}} <template v-if="!file.is.project">({{file.file_extension}})</template></span>
      <span v-if="file.width && file.height && !file.is.document">{{file.width}}x{{file.height}}</span>
    </div>
    <div class="flex text-sm justify-between text-gray-200">
      <span>{{file.file_size_gb}}</span>
      <span class="text-gray-200 text-sm" v-if="file.duration">{{formatDuration(file.duration)}}</span>
    </div>


    <template v-if="file.is.ownedByCurrentUser">
      <u-divider class="my-2" />
      <ul class="flex flex-col gap-1">
        <li>
          <u-button
            @click="toggleFavorite"
            color="gray"
            :loading="isFavoriteLoading"
            :icon="file.favorite ? COMMON_ICONS.favoriteFill : COMMON_ICONS.favoriteLine"
            :label="file.favorite ? 'Remove Favorite' : 'Add to Favorites'"
            :ui="{
          base: 'w-full',
          icon: {
            base: file.favorite && !isFavoriteLoading ? 'text-red-500' : 'text-gray-500',
          }
        }"
          />
        </li>
      </ul>

    </template>

  </div>

</template>

<script setup>
  const props = defineProps({
    file: Object
  });

  const isFavoriteLoading = ref(false);
  async function toggleFavorite() {
    try {
      isFavoriteLoading.value = true;
      await useFileActions().toggleFavorite({file: props.file});
    } catch (e) {
      useErrorToast().add();
    } finally {
      isFavoriteLoading.value = false;
    }
  }
</script>
