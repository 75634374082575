<template>
  <div class="f-thumbs_roll">
    <div class="f-thumbs_roll-container relative py-0.5 px-0 h-24">
      <u-icon v-if="hasPrev" @click="prevPage" name="i-ri-arrow-left-s-line" class="text-gray-400 text-3xl absolute top-1/2 -translate-y-1/2 cursor-pointer left-1" />
      <u-icon v-if="hasNext" @click="nextPage" name="i-ri-arrow-right-s-line" class="text-gray-400 text-3xl absolute top-1/2 -translate-y-1/2 cursor-pointer right-1" />

      <div class="absolute left-12 right-12 overflow-hidden text-center" ref="rollContainerEl" @wheel.passive="rollerMouseWheelScroll">
        <div class="f-thumbs_roll-roll-inner flex transition-{margin} duration-500 ease-in-out" ref="rollEl" :style="{width: `${rollWidth}px`, marginLeft: `${adjustedRollPosition}px`}">
          <div
              v-for="(url, index) in thumbUrls"
              :key="url"
              :style="itemStyle"
              :class="[
                'flex items-center cursor-pointer overflow-hidden border-4 border-solid transition-all duration-300',
                {
                  'f-thumbs_roll-roll-inner-selected border-green': index === currentIndex,
                  'border-transparent hover:border-blue-300': index !== currentIndex
                }
              ]"
              @click="select(index)"
          >
            <img class="f-thumbs_roll-roll-inner-page-image" :src="url" :alt="`page ${currentIndex + 1}`" :width="thumbWidth" :height="thumbHeight" loading="lazy" draggable="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import debounce from 'lodash.debounce';
  import Hammer from 'hammerjs';

  const BORDER_WIDTH = 4; //defined in css

  const emit = defineEmits(['select']);
  const props = defineProps({
    thumbUrls: {
      type: Array,
      required: true
    },
    currentIndex: {
      type: Number,
      default: 0
    },
    thumbHeight: {
      type: Number,
      default: 80
    },
    thumbWidth: {
      type: Number,
      default: 80
    },
    thumbMargin: {
      type: Number,
      default: 3
    }
  });

  let hammerInstance = null;
  const rollEl = ref();
  const rollContainerEl = ref();

  const rollPosition = ref(0);
  const panDelta = ref(0);
  let originalTransition = null;
  let isPanning = false;

  const widthPerItem = computed(() => props.thumbWidth + props.thumbMargin + (BORDER_WIDTH * 2));
  const rollWidth = computed(() => (props.thumbUrls.length * widthPerItem.value));
  const adjustedRollPosition = computed(() => rollPosition.value + panDelta.value);
  const itemStyle = computed(() => ({
    marginRight: `${props.thumbMargin}px`,
    width: `${props.thumbWidth + (BORDER_WIDTH * 2)}px`,
    height: `${props.thumbHeight + (BORDER_WIDTH * 2)}px`
  }));
  const hasPrev = computed(() => props.currentIndex > 0);
  const hasNext = computed(() => props.currentIndex + 1 < props.thumbUrls.length);

  const {width: containerWidth, left: containerLeft} = useElementBounding(rollContainerEl);

  function select(index) {
    if (!isPanning) {
      emit('select', index);
    }
  }
  function prevPage() {
    if (hasPrev.value) {
      select(props.currentIndex - 1);
    }
  }
  function nextPage() {
    if (hasNext.value) {
      select(props.currentIndex + 1);
    }
  }

  //pan
  function onHammerInput(e) {
    e.srcEvent.stopPropagation();
  }
  function panStart() {
    isPanning = true;
  }
  function panMove({deltaX}) {
    panDelta.value = deltaX;
  }
  function panEnd() {
    rollPosition.value += panDelta.value;
    panDelta.value = 0;
    restrictRollPosition();
  }

  //scroll
  async function rollerMouseWheelScroll(e) {
    const numPixelsPerStep = 10;

    //normalize scroll distance
    const delta = e.wheelDelta * 5;
    const newRollPosition = rollPosition.value + delta / 120 * numPixelsPerStep;
    //weird math for minLeft caused by the need to have .text-center on the container
    const minLeft = (-1 * rollWidth.value) - containerWidth.value + props.thumbWidth;
    const maxLeft = containerWidth.value - props.thumbWidth;

    rollPosition.value = Math.max(Math.min(newRollPosition, maxLeft), minLeft);

    await nextTick();
    restrictRollPositionDebounced();
  }

  //util
  const restrictRollPositionDebounced = debounce(restrictRollPosition, 100);

  function restrictRollPosition() {
    //adjust for panning/scrolling too far left or right
    const minLeft = (rollWidth.value - containerWidth.value) * -1;

    if (containerWidth.value >= rollWidth.value) {
      rollPosition.value = 0;
    } else if (rollPosition.value > 0 || rollPosition.value < minLeft) {
      rollEl.value.style.transition = originalTransition;
      rollPosition.value = Math.max(Math.min(rollPosition.value, 0), minLeft);
    }

    //making sure we will not perform click on an img if that was the pan starting point
    setTimeout(() => {
      rollEl.value.style.transition = 'none';
      isPanning = false;
    }, 500);
  }

  onMounted(() => {
    originalTransition = getComputedStyle(rollEl.value).transition;
    rollEl.value.style.transition = 'none'; //remove the designated transition and only add it on automatic adjustment

    //todo: test ssr
    // const Hammer = require('hammerjs'); //note: needs to be imported in mounted hook so SSR with nuxt app would not break
    hammerInstance = new Hammer(rollEl.value);
    hammerInstance.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL, threshold: 0});
    hammerInstance.on('hammer.input', onHammerInput);
    hammerInstance.on('panstart', panStart);
    hammerInstance.on('pan', panMove);
    hammerInstance.on('panend pancancel', panEnd);
  });

  onUnmounted(() => {
    hammerInstance.off('hammer.input', onHammerInput);
    hammerInstance.off('panstart', panStart);
    hammerInstance.off('pan', panMove);
    hammerInstance.off('panend pancancel', panEnd);
    hammerInstance.destroy();
  });

  watch(() => props.currentIndex, async (newVal) => {
    if (newVal || newVal === 0) {
      await nextTick();

      //adjust roll position
      const selectedRect = useElementBounding(rollEl.value.querySelector('.f-thumbs_roll-roll-inner-selected'));

      if (containerWidth.value >= rollWidth.value) {
        rollPosition.value = 0;
        return;
      }

      rollEl.value.style.transition = originalTransition;

      const minLeft = (rollWidth.value - containerWidth.value) * -1;
      const rollAdjustment = (-1 * (selectedRect.left.value + (selectedRect.width.value / 2) - (containerWidth.value / 2) - containerLeft.value));
      const newRollPosition = rollPosition.value + rollAdjustment;

      rollPosition.value = Math.max(Math.min(newRollPosition, 0), minLeft);

      //give a chance for the transition to finish running
      setTimeout(() => {
        if (rollEl.value) {
          rollEl.value.style.transition = 'none';
        }
      }, 500);
    }
  });

</script>
