<template>
  <div class="f-file_detail-stage absolute m-h-full min-w-full inset-0 z-1 group">

    <file-detail-navigation-button @click="prevFile" v-if="hasPrev" />
    <file-detail-navigation-button @click="nextFile" v-if="hasNext" is-next />

    <div v-if="isLoading" class="w-full h-full flex-center">
      <core-busy-dots />
    </div>

    <!-- note: cannot use transition-group here because safari messes that up for some reason -->
    <div
      :class="[
        'f-file_detail-stage-transitioner absolute w-full h-full z-100',
        {'f-file_detail-stage-transitioner-animate': animateTransitions}
      ]"
      ref="stageTransitioner"
      v-show="isReady"
    >
      <template v-if="availableSpace">
        <div
          v-for="(stagedFile, index) in stagedFiles"
          :key="stagedFile.id"
          :class="[
            'f-file_detail-stage-feature_wrapper absolute w-full h-full',
            {
              'stage_current opacity-1': isFileInView(stagedFile),
              'transition-all duration-200': animateTransitions,
              'opacity-0': !isFileInView(stagedFile) && animateTransitions
            }
          ]"
          :style="featureStylesMap[stagedFile.id]"
        >
          <!-- note: file vs stagedFile causes for a weird edge case with detail-video - we can address by matching the current file with the received library or file list in the store -->
          <component
            class="f-file_detail-stage-feature absolute flex-center transition-all duration-500"
            :is="getFeatureComponent(stagedFile)"
            :file="stagedFiles[index]"
            :available-space="availableSpace"
            :is-active="isFileInView(stagedFile) && !isPanning"
            :is-transitioning="isPanning"
            @full-screen-change="onFullScreenChange"
            @download="downloadFile"
            :inert="!isFileInView(stagedFile)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
  import Hammer from 'hammerjs';
  import throttle from 'lodash.throttle';
  import {storeToRefs} from 'pinia';
  import {FileDetailAudio, FileDetailVideo, FileDetailImage} from '#components';

  const emit = defineEmits(['exit', 'buy-video-plan', 'download']);
  const props = defineProps({
    availableSpace: Object,
    isLoading: Boolean
  });

  const filesStore = useFilesStore();
  const {file, files: storeFiles} = storeToRefs(filesStore);

  const stageTransitioner = ref();

  let hammer = null;
  const isReady = ref(false);
  const animateTransitions = ref(true);
  const isPanning = ref(false);
  let isSwipeDown = false;
  let panElCurrent = null;
  let panElPrev = null;
  let panElNext = null;
  let inFullScreen = false;
  let isExiting = false;

  const fileIndexInStore = computed(() => storeFiles.value?.findIndex(sf => sf.id === file.value.id));
  const stagedFilesBounds = computed(() => (fileIndexInStore.value > -1
    ? {
      start: Math.max(fileIndexInStore.value - FILE_STAGED_FILES_BEFORE_CURRENT, 0),
      end: Math.min(fileIndexInStore.value + FILE_STAGED_FILES_AFTER_CURRENT + 1, storeFiles.value.length)
    }
    : null
  ));
  const stagedFiles = computed(() => (stagedFilesBounds.value
    ? storeFiles.value?.filter((sf, i) => i >= stagedFilesBounds.value.start && i < stagedFilesBounds.value.end)
    : [file.value]
  ));
  const fileIndexInStaged = computed(() => stagedFiles.value.findIndex(sf => sf.id === file.value.id));

  const hasNext = computed(() => fileIndexInStore.value > -1 && fileIndexInStore.value < storeFiles.value?.length - 1);
  const hasPrev = computed(() => fileIndexInStore.value > 0);

  //stage setup
  function getFeatureComponent(file) {
    if (!file) {
      return null;
    }
    if (file.is.video) {
      return FileDetailVideo;
    }
    if (file.is.audio) {
      return FileDetailAudio;
    }

    return FileDetailImage;
  }

  function isFileInView(aFile) {
    return aFile.id === file.value.id;
  }

  //stage navigation
  const router = useRouter();
  const route = useRoute();

  let featureStylesMap = reactive({});

  function resetFeatureStylesMap() {
    featureStylesMap = stagedFiles.value.reduce((map, sf, index) => {
      map[sf.id] = {
        transform: `translate3d(${isFileInView(sf) ? 0 : (index > fileIndexInStaged.value ? 100 : -100)}%, 0, 0`
      };

      return map;
    }, {});
  }

  watch(fileIndexInStaged, resetFeatureStylesMap, {immediate: true});
  watch(file, resetFeatureStylesMap, {immediate: true});

  function nextFile() {
    if (!inFullScreen && hasNext.value) {

      const toFile = stagedFiles.value[fileIndexInStaged.value + 1];

      if (toFile) {
        router.push({
          params: {
            file_id: toFile.id
          },
          query: route.query,
          hash: route.hash
        });
      }
    }
  }
  function prevFile() {
    if (!inFullScreen && hasPrev.value) {
      const toFile = stagedFiles.value[fileIndexInStaged.value - 1];

      if (toFile) {
        router.push({
          params: {
            file_id: toFile.id
          },
          query: route.query,
          hash: route.hash
        });
      }
    }
  }

  const keydownListener = throttle(e => {
    if (!useFModals().areModalsOpen.value) {
      const code = e.keyCode;

      if (code === 37) {
        prevFile();
      }
      if (code === 39) {
        nextFile();
      }
    }
  }, 200);

  //pan/drag interactions
  function setElemTranslate(elem, translateX = 0, translateY = 0) {
    if (elem) {
      const rotate = -90 * translateY / 100;
      elem.style.transform = `translate3d(${translateX}%, ${translateY}%, 0) rotate(${rotate}deg)`;

      //note: this fixes weird behavior on swipe down right before exit
      if (isExiting) {
        featureStylesMap[file.value.id] = {transform: `translate3d(${translateX}%, ${translateY}%, 0) rotate(${rotate}deg)`};
      }
    }
  }

  function panStart(e) {
    const isTargetInFeature = Array.from(stageTransitioner.value.querySelectorAll('.f-file_detail-stage-feature'))
        .some(feature => feature.contains(e.target));

    if (!isTargetInFeature) {
      return;
    }

    panElCurrent = stageTransitioner.value.querySelector('.stage_current');
    isSwipeDown = e.direction === Hammer.DIRECTION_DOWN;
    animateTransitions.value = false;
    isPanning.value = true;

    if (!isSwipeDown) {
      panElPrev = panElCurrent.previousElementSibling;
      panElNext = panElCurrent.nextElementSibling;
    }
  }

  function pan(e) {
    if (!isPanning.value) {
      return;
    }

    const percent = isSwipeDown
        ? (100 / props.availableSpace.height) * e.deltaY
        : (100 / props.availableSpace.width) * e.deltaX;

    if (e.isFinal) {
      //note: finalize pan interaction
      animateTransitions.value = true;

      //note: using bitwise operator in order to cover several cases for e.direction
      if (isSwipeDown) {
        if (percent > FILE_SWIPE_DOWN_THRESHOLD_PERCENT && (e.direction & Hammer.DIRECTION_DOWN)) {
          isExiting = true;
          setElemTranslate(panElCurrent, 0, 100);
          setTimeout(() => emit('exit'), 300);
        } else {
          setElemTranslate(panElCurrent, 0, 0);
        }
      } else {
        if (hasPrev.value && percent > FILE_PAN_THRESHOLD_PERCENT
            && (e.direction & Hammer.DIRECTION_RIGHT || e.direction === Hammer.DIRECTION_NONE)) {

          setElemTranslate(panElCurrent, 100);
          setElemTranslate(panElPrev, 0);
          setElemTranslate(panElNext, 100);
          prevFile();

        } else if (hasNext.value && percent < -FILE_PAN_THRESHOLD_PERCENT
            && (e.direction & Hammer.DIRECTION_LEFT || e.direction === Hammer.DIRECTION_NONE)) {
          setElemTranslate(panElCurrent, -100);
          setElemTranslate(panElPrev, -100);
          setElemTranslate(panElNext, 0);
          nextFile();
        } else {
          setElemTranslate(panElCurrent, 0);
          setElemTranslate(panElPrev, -100);
          setElemTranslate(panElNext, 100);
        }
      }

      panElCurrent = null;
      panElPrev = null;
      panElNext = null;

      setTimeout(() => (isPanning.value = false), 100);
    } else {
      //note: update pan interaction progress
      if (isSwipeDown) {
        setElemTranslate(panElCurrent, 0, Math.max(percent, 0));
      } else {
        setElemTranslate(panElCurrent, percent);
        setElemTranslate(panElPrev, percent - 100);
        setElemTranslate(panElNext, percent + 100);
      }
    }
  }

  //feature event handlers
  function onFullScreenChange(isInFullScreen) {
    inFullScreen = isInFullScreen;
  }
  function downloadFile() {
    emit('download');
  }

  onMounted(() => {
    document.addEventListener('keydown', keydownListener);

    hammer = new Hammer.Manager(stageTransitioner.value);
    hammer.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 10}));
    hammer.on('panstart', panStart);
    hammer.on('pan', pan);

    setTimeout(() => (isReady.value = true), 500);
  });

  onUnmounted(() => {
    document.removeEventListener('keydown', keydownListener);

    hammer.off('panstart', panStart);
    hammer.off('pan', pan);
    hammer.destroy();
  });

</script>

<style lang="scss" scoped>
  .f-file_detail-stage-transitioner-animate .f-file_detail-stage-feature_wrapper {
    //transition: transform 0.3s ease-out; //note: cannot @include transition because it comes with a delay
  }
</style>
