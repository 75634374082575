<template>
  <div class="fixed inset-0 z-50">
    <file-detail-manager :file-id="route.params.file_id" :file-context="fileContext" />
  </div>
</template>

<script setup>
  const route = useRoute();
  const {share_token: shareToken, user_id_or_slug: userId} = route.params;
  const {isAuthenticated} = useSessionStore();

  const fileContext = {
    contextType: FILE_CONTEXTS.file,
    shareToken,
    userId,
    query: route.query
  };

  onMounted(() => {
    //set up the signup cookie which allows us to link users to the sharer during signup.
    if (!isAuthenticated && shareToken) {
      useExternalRedirect().setSignupCookie({
        value: userId
      });
    }
  });
</script>
