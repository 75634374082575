//util
function canHaveChildren(album) {
  //album details have parent album but no ancestor_ids 🤣🤣🤣
  if (album.parent_album) {
    return album.parent_album.ancestor_ids?.length < ALBUM_HIERARCHY_MAX_DEPTH - 2;
  }

  //album list items have array of ancestor_ids but no parent album 🤣🤣🤣
  else if (album.ancestor_ids) {
    return album.ancestor_ids.length < ALBUM_HIERARCHY_MAX_DEPTH - 1;
  }

  //root detail album 🤣🤣🤣
  return true;
}

function getAlbumDisplayDate(album) {
  let result = '';

  if (album.start_date) {
    result += dayjs(album.start_date).format('l');
  }

  if (album.end_date) {
    result += ' - ' + dayjs(album.end_date).format('l');
  }

  return result.trim();
}

export function albumMapper(album) {
  const dayjs = useDayjs();

  //deal with v3 and v4 payloads
  album.avatarImage = album.images?.preview
    ? updateQueryParameters(album.images.preview, {width: 500, height: 500, crop: 'fit', rotate: album.images.rotation})
    :album.images?.medium?.url;

  album.user_id = album.user_id || album.user?.id;

  album.canHaveChildren = canHaveChildren(album);

  album.displayDate = (() => {
    let result = '';

    if (album.start_date) {
      result += dayjs(album.start_date).format('l');
    }

    if (album.end_date) {
      result += ' - ' + dayjs(album.end_date).format('l');
    }

    return result.trim();
  })();

  album.isAlbum = true;
  album.isNested = album.parent_album || album.ancestor_ids?.length;

  return album;
}

export function sharedAlbumMapper(item) {
  return Object.assign(item.item, {
    shareToken: item.share_token,
    share_token: item.share_token,
    user: item.user,
    sharedItemId: item.id,
    user_id: item.user.id,
    isAlbum: true,
    avatarImage: item.item.images && updateQueryParameters(item.item.images.preview, {
      width: 512,
      height: 512,
      crop: 'fit',
      quality: 60
    })
  });
}
