<template>
  <u-context-menu
    class="core-context-menu dark select-none"
    v-model="isOpen"
    :virtual-element="virtualElement"
    :popper="popperConfig"
    :ui="{rounded: 'rounded'}"
    @contextmenu.prevent
  >
    <template v-if="model"><slot /></template>
  </u-context-menu>
</template>

<script setup>
  const props = defineProps({
    scroller: Object
  });

  const model = defineModel();
  const isOpen = defineModel('isOpen');
  const {x, y} = useMouse();
  const {y: windowY} = useWindowScroll();
  const virtualElement = ref({ getBoundingClientRect: () => ({}) });

  const popperConfig = {
    offsetDistance: 20,
    placement: 'right',
    arrow: true
  };

  watch(isOpen, () => {
    const top = unref(y) - unref(windowY);
    const left = unref(x);

    virtualElement.value.getBoundingClientRect = () => ({
      width: 0,
      height: 0,
      top,
      left
    });
  });

  function close() {
    isOpen.value = null;
  }

  if (props.scroller) {
    const {x, y} = useScroll(props.scroller, {thottle: 250});
    watch(y, close);
    watch(x, close);
  }
</script>
