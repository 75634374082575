<template>
  <core-select-menu-selections v-model:selected="selected" context-type="person" />
  <u-select-menu
    class="people-select-menu"
    v-model="selected"
    v-model:query="inputStr"
    :size="size"
    :leading-icon="COMMON_ICONS.person"
    by="id"
    option-attribute="name"
    multiple
    :searchable="getPeople"
    :searchable-lazy="false"
    show-create-option-when="always"
    ref="selectElement"
    :searchable-placeholder="searchablePlaceholder"
    :ui-menu="{base: 'scrollbar-light'}"
    :ui="ui"
    :popper="popper"
  >
    <template #option="{option}">
      <template v-if="option.isCreate">
        <span class="flex-shrink-0">New Person:</span>
        <people-avatar :person="option" size="md" />
        <span class="block break-long-string">{{inputStr}}</span>
      </template>
      <template v-else>
        <people-avatar :person="option" size="md" />

        <div v-if="option.name" class="flex gap-2">
          <span class="break-long-string" v-if="option.name">{{option.name}}</span>
        </div>

        <span v-else class="italic text-gray-400">Unnamed</span>
      </template>
    </template>

    <template #empty>No people.</template>

<!--    <template #option-create="{ option }">
      <span class="flex-shrink-0">New Person:</span>
      <people-avatar :person="option" size="md" />
      <span class="block break-long-string">{{ option.name }}</span>
    </template>-->


    <template #label>
      <template v-if="selected.length">{{selected.length}} Selected</template>
      <template v-else>{{placeholderText}}</template>
    </template>

    <slot />

  </u-select-menu>
</template>

<script setup>
  const emit = defineEmits(['update:modelValue']);
  const props = defineProps({
    modelValue: Array,
    autofocus: Boolean,
    disableCreate: Boolean,
    size: {
      type: String,
      default: 'lg'
    },
    popper: Object,
    ui: Object
  });

  const isLoading = ref(false);
  const placeholderText = 'Select People';
  const selected = ref([]);
  const selectElement = ref();

  const inputStr = ref('');
  const createOption = {
    isCreate: true
  };

  //note: this construct is necessary because for some reason selecting a person triggers a new request to the api
  const currentOptionsDescriptor = {
    search: '',
    options: null
  };

  const searchablePlaceholder = computed(() => !props.disableCreate ? 'Search or create people (start typing)' : 'Search people (start typing)');

  watch(inputStr, () => (isLoading.value = true));

  async function getPeople(search) {
    isLoading.value = true;

    if (search !== currentOptionsDescriptor.search || !currentOptionsDescriptor.options) {
      currentOptionsDescriptor.search = search;
      const data = await usePeopleApi().getPeople({search, order: PEOPLE_SORT_OPTIONS[2].param});
      currentOptionsDescriptor.options = data.people.filter(p => p.name.toLowerCase().includes(search.toLowerCase()));;

      if (search && !props.disableCreate) {
        currentOptionsDescriptor.options.unshift(createOption)
      }
    }

    isLoading.value = false;

    return currentOptionsDescriptor.options;
  }

  watch(selected, (people) => {
    people.forEach(async (p, index) => {
      if (!p.isCreate) {
        return;
      }

      //parse the single field name to a payload for complex name
      const person = parseName(inputStr.value);

      const newPerson = await usePeopleApi().createPerson({person});

      selectElement.value.query = '';
      selected.value.splice(index, 1, newPerson);
    });

    emit('update:modelValue', people);

  }, {deep: true});

  watch(() => props.modelValue, newValue => {
    selected.value = newValue;
  },
  {
    immediate: true
  });

  onMounted(() => {
    if (props.autofocus) {
      // - using the selectMenu ref threw an error
      // - using the native autofocus attr on the u-select worked 1 time per page refresh
      useFocus(document.querySelector('.people-select-menu button'), {
        initialValue: true
      });
    }
  });
</script>
