<template>
  <div class="album-move-modal">
    <core-modal-header>
      <template v-if="isMulti">Move the {{count}} selected albums to...</template>
      <template v-else>Move {{albumName}} to...</template>
    </core-modal-header>

    <core-modal-body>
      <!--todo: verify this display-->
      <u-alert
        v-if="isAtMaxDepth"
        class="mb-4"
        color="red"
        title="Your album was not moved."
        description="Moving it would exceed the maximum album depth."
      />
      <album-navigator @select="onSelection" @change="onChange" :parent-id="currentParentId" :disable-ids="albumIdsToMove" show-root />
    </core-modal-body>
  </div>
</template>

<script setup>
  const props = defineProps({
    albums: Array,
    currentParentId: String,
    modal: Object
  });

  const albumsStore = useAlbumsStore();
  const count = props.albums.length;
  const isMulti = count > 1;
  const albumName = html2text(props.albums[0].name);
  const albumIdsToMove = props.albums.map(a => a.id);
  const isAtMaxDepth = ref(false);

  function onChange() {
    isAtMaxDepth.value = false;
  }

  async function onSelection(album) {
    try {
      await albumsStore.moveAlbums({albums: props.albums, destId: album.id, sourceId: props.currentParentId});

      useSuccessToast().add({
        id: 'album-move-success',
        description: isMulti
          ? `Your ${count} selected albums were moved.`
          : `Your album ${albumName} was moved.`
      });

      props.modal.close();
    } catch (err) {
      if (err.response && err.response.status === 409) {
        isAtMaxDepth.value = true;
      } else {
        useErrorToast().add();
      }
    }
  }
</script>
