<template>
  <u-avatar
    :size="size"
    :src="user.avatarImage"
    :alt="user.name"
    :ui="uiConfig"
    img-class="object-cover"
  />
</template>

<script setup>
  const props = defineProps({
    user: Object,
    ui: Object,
    size: {
      type: String,
      default: 'md'
    }
  });

  const uiConfigBase = {
    background: `${props.user.profileClassName} dark:${props.user.profileClassName}`,
    placeholder: 'text-white dark:text-white'
  };

  const uiConfig = Object.assign(uiConfigBase, props.ui);
</script>
