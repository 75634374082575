<template>
  <div v-if="!isOnline" class="backdrop-blur-sm z-[1000] fixed-cover flex-center bg-gray-200/75">
    <section class="flex-center bg-white shadow flex-col p-8 max-w-sm">
      <u-icon name="i-ri-wifi-off-line" class="text-salmon mb-3 w-12 h-12" />
      <p class="text-3xl text-center mb-5 font-serif text-charcoal">Your browser is currently offline.</p>
      <core-busy-dots />
    </section>
  </div>
</template>

<script setup>
  const isOnline = useOnline()
</script>
