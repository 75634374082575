<template>
  <transition name="f-fade">
    <u-button
      size="xs"
      class="absolute bottom-0 left-1/2 -translate-x-1/2 transition-all z-[9] lg:z-10"
      leading-icon="i-ri-arrow-up-line"
      trailing-icon="i-ri-arrow-up-line"
      :ui="{font: 'uppercase', rounded: 'rounded-t-xl rounded-b-none', size: {'xs': 'text-2xs'}}"
      label="Back to Top"
      v-show="isActive"
      @click="top"
    />
  </transition>
</template>

<script setup>
  const {activeThreshold: threshold, boundScroller: scrollingEl} = useBackToTop();
  const {y: scrollY} = useScroll(scrollingEl.value, {throttle: 250});
  const isActive = computed(() => scrollY.value > threshold.value);

  function top() {
    scrollingEl.value.scrollTo(0, 0);
  }

</script>
