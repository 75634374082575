import {
  PeopleDetailsModal,
  FileUpdateContextThumbnailModal,
  PeopleEditForm,
  PeopleHidePersonModal,
  PeopleShowHidePeopleModal,
  PeopleConfirmPersonMergeModal,
  PeopleBulkMergeIntoModal
} from '#components';

export function usePeopleActions() {

  function addEdit({person} = {}) {
    return new Promise(async resolve => {
      const modal = useFModal({
        id: 'add-edit-person',
        clickToClose: false,
        component: PeopleEditForm,
        props: {person},
        eventHandlers: {
          success({newPerson}) {
            resolve(newPerson);
            modal.close();
          }
        }
      });

      modal.open();
    });
  }

  function hidePerson({person}) {
    return new Promise(resolve => {
      const modal = useFModal({
        id: 'hide-person',
        component: PeopleHidePersonModal,
        props: {
          person
        },
        eventHandlers: {
          success() {
            modal.close();
            resolve();
          }
        }
      });

      modal.open();
    });
  }

  async function showHidePeople() {
    return new Promise(resolve => {
      useFModal({
        id: 'show-hide-people',
        component: PeopleShowHidePeopleModal,
        size: 'xl',
        onClose() {
          resolve();
        }
      }).open();
    });
  }

  /*share({person}) {
    useFModal({
      id: 'share-person',
      component: CoreShareModal,
      props: {
        shareObject: person,
        shareType: 'person'
      }
    }).open()
  }*/

  function editThumbnail({person}) {
    const modal = useFModal({
      id: 'edit-person-cover',
      component: FileUpdateContextThumbnailModal,
      size: 'fullscreen',
      showClose: false,
      props: {
        contextType: FILE_CONTEXTS.person,
        context: person
      },
      eventHandlers: {
        async complete(file) {
          try {
            await useFileActions().setFileAsPersonCover({person, file});
            modal.close();
          } catch (e) {
            useErrorToast().add();
          }
        }
      }
    });

    modal.open();
  }

  function confirmPersonMerge({person, toPerson}) {
    return new Promise(res => {
      const modal = useFModal({
        id: 'confirm-person-merge',
        component: PeopleConfirmPersonMergeModal,
        props: {
          person,
          toPerson
        },
        eventHandlers: {
          success() {
            res({merged: true});
          }
        }
      });

      modal.open();
    })
  }

  function viewDetails({person}) {
    useFModal({
      id: 'person-details',
      component: PeopleDetailsModal,
      size: 'lg',
      props: {
        person
      }
    }).open();
  }

  function bulkMergeInto({person}) {
    return new Promise(resolve => {
      const modal = useFModal({
        id: 'bulk-merge-into',
        component: PeopleBulkMergeIntoModal,
        size: 'xl',
        props: {
          person
        },
        eventHandlers: {
          success({error} = {}) {
            useSuccessToast().add({
              description: error
                ? 'There was an error merging some of your selections, but we did our best.'
                : 'People have been merged successfully.'
            });

            modal.close();
            useEventBus('refresh-people-list').emit();
            resolve();
          }
        }
      })

      modal.open();
    });

  }

  function getContextActions({person, userId = useSessionStore().currentUserId}) {
    return {
      /*upload: {
        label: 'Upload Files',
        icon: COMMON_ICONS.upload,
        click: () => useUploadActions().openUploaderModal({person, contextType: FILE_CONTEXTS.person, contextId: person.id})
      },
      */

      addFiles: {
        label: 'Add Files',
        icon: COMMON_ICONS.add,
        click: () => useFileActions().addFilesToContext({
          context: person,
          contextType: FILE_CONTEXTS.person
        })
      },

      edit: {
        label: 'Edit Details',
        icon: COMMON_ICONS.edit,
        click: async () => {
          const editedPerson = await addEdit({person});
          const routeIdOrSlug = useRoute().params.person_id_or_slug;
          if (routeIdOrSlug === (editedPerson.id || editedPerson.slug)) {
            // if we are on a person details page, we need to navigate to the new person
            const newRoute = useBuildRoute().toPerson({person: editedPerson});
            navigateTo(newRoute, {replace: true});
          }
        }
      },
      editCover: {
        label: 'Edit Cover Photo',
        icon: COMMON_ICONS.makeCover,
        click: () => {
          if (!person.files_count) {
            useInfoToast().add({description: 'You must add photos to this person before you can set a cover image.'});
          } else {
            editThumbnail({person})
          }
        }
      },

      viewSlideshow: {
        label: 'View Slideshow',
        icon: COMMON_ICONS.slideshow,
        click: () => useSlideshow({
          container: person,
          fileContext: FILE_CONTEXTS.person,
          userId
        }).start()
      },

      print: {
        label: 'Print',
        icon: COMMON_ICONS.print,
        click: () => {
          if (!person.files_count) {
            useErrorToast().add('You must add photos to this person before you can print.');
          } else {
            useProjectActions().openContainerPrintModal({
              context: person,
              contextType: FILE_CONTEXTS.person
            })
          }
        }
      },

      hide: {
        label: 'Hide Person',
        icon: COMMON_ICONS.personHide,
        click: async () => {
          await hidePerson({person});
          navigateTo(useBuildRoute().toPeople());
        }
      },

      viewDetails: {
        label: 'View Details',
        icon: COMMON_ICONS.information,
        click: () => viewDetails({person})
      },

      bulkMergeInto:{
        label: 'Bulk Merge',
        icon: 'i-mdi-vector-combine',
        click: async () => bulkMergeInto({person})
      }

    }
  }

  return {
    addEdit,
    hidePerson,
    showHidePeople,
    editThumbnail,
    bulkMergeInto,
    confirmPersonMerge,
    viewDetails,
    getContextActions
  };
}
