export function html2text(html) {
  return html
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

export function parseAndSanitizeTextToParagraphs(text) {
  // Simple sanitization function to remove HTML tags
  function sanitize(input) {
    const div = document.createElement('div');
    div.textContent = input; // Assign textContent to escape HTML
    return div.innerHTML; // Returns sanitized text as plain HTML
  }

  // Split the text by double line breaks
  let paragraphs = text.split(/\n\s*\n/);

  // Sanitize and trim extra whitespace from each paragraph, filter out empty ones
  paragraphs = paragraphs
    .map(paragraph => sanitize(paragraph.trim()))
    .filter(paragraph => paragraph.length > 0);

  return paragraphs;
}
