<template>
  <u-textarea
    v-if="multiline"
    autoresize
    :rows="1"
    :maxrows="8"
    v-model="localModel"
    @blur="update"
    @keyup.esc="onEscKey"
    @keydown.esc.stop
    color="white"
    variant="outline"
    :ui="ui"
  />
  <u-input
    v-else
    v-model="localModel"
    @blur="update"
    @keyup.esc="onEscKey"
    @keydown.esc.stop
    color="white"
    variant="outline"
    :ui="ui"
  />
</template>

<script setup>
  const props = defineProps({
    multiline: Boolean
  });

  const model = defineModel();
  const localModel = ref(props.modelValue);
  watch(model, newVal => (localModel.value = newVal));

  const ui = {
    base: 'disabled:cursor-default scrollbar-light',
    placeholder: 'dark:placeholder-gray-700',
    color: {
      white: {
        outline: 'ring-0 focus:ring-0 border-b border-white/5 focus:border-white/50 transition-colors px-0 py-1'
      }
    }
  };

  function update() {
    model.value = localModel.value;
  }

  function onEscKey(e) {
    e.stopPropagation();
    e.srcElement.blur();
  }

</script>
