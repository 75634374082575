<template>
  <div class="flex flex-col h-full transform transition duration-300" :style="{transform: `translateX(${drawerAdjust}px)`}">

    <!-- toolbar -->
    <div class="flex-none">
      <div class="bg-charcoal-700 p-2 lg:p-3 flex items-center gap-2 justify-between">
        <div class="text-white inline-flex gap-0 sm:gap-2 items-center text-sm sm:text-base">
          <core-selection-toolbar-button
            dark
            variant="ghost"
            :icon="COMMON_ICONS.close"
            @click="close()"
          />
          <slot name="title" />
        </div>

        <div class="inline-flex items-center gap-2">
          <div class="hidden sm:block text-gray-300">
            <template v-if="isMultiSelect">Select 1 - {{maxSelectionCount}} Items</template>
            <template v-else>Select 1 item</template>
          </div>

          <core-responsive-button
            :disabled="!selection.count.value"
            :loading="isSaving"
            v-if="isMultiSelect"
            color="primary"
            size="sm"
            @click="complete"
            icon="i-ri-check-line"
          >{{`Add Selected ${selection.count.value ? `(${selection.count.value})` : ''}`}}</core-responsive-button>
        </div>
      </div>
    </div>

    <div class="flex flex-row w-full h-full">
      <div class="flex flex-col">
        <div class="flex-none border-b border-gray-200 py-2 px-2 lg:px-5 lg:py-3 lg:mb-3">
          <file-list-controls :files-store-id="filesStoreId" :is-in-picker="true" />
        </div>

        <div
          ref="fileListContainer"
          class="lg:pb-3 pl-3 lg:pl-5 pr-1 "
          :style="`width: ${containerRect.width}px; height: ${containerRect.height}px`">

          <file-list
            :files-store-id="filesStoreId"
            :context-type="contextType"
            :context="context"
            :disable-selection="disableMultiSelection"
            disable-toolbar
            disable-context-menu
            disable-back-to-top
            loading-text="Loading Your Files"
            @list-item-click="selectItem"
          >
            <template #empty-state>
              <core-empty-state
                heading="No Files"
                description="No files were found for you to choose from."
                :icon="COMMON_ICONS.noFiles"
              />
            </template>
          </file-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {makeFilesStore} from '~/stores/files.js';

  import debounce from "lodash.debounce";

  const filesStoreId = 'filePickerStore';

  const emit = defineEmits(['close', 'complete']);
  const props = defineProps({
    maxSelectionCount: {
      type: Number,
      default: FILE_MAX_SELECTION
    },
    statePersistenceId: String, //the ID to be used to store the picker state to session storage
    contextType: String,
    context: Object
  });

  const disableMultiSelection = computed(() => props.maxSelectionCount === 1);

  const filesStore = makeFilesStore(filesStoreId)();
  const selection = useSelection();
  const isMultiSelect = computed(() => props.maxSelectionCount !== 1);

  const fileListContainer = ref(null);

  const containerRect = ref({
    width: 0,
    height: 0
  });

  function close() {
    emit('close');
  }

  /*async function upload() {
    await useUploadActions().openUploaderModal({filesStoreId});
  }*/

  function selectItem({item} = {}) {
    if (isMultiSelect.value) {
      selection.toggle({item});
    } else {
      emit('complete', {items: [item]});
    }
  }

  const isSaving = ref(false);
  async function complete() {
    isSaving.value = true;
    emit('complete', {items: selection.selected.value});
    await waitFor(5000); //artificial delay to prevent user form mashing button
    isSaving.value = false;
  }

  const onWindowResize = debounce(() => {
    createFileListContainerRect();
  }, 100);

  function createFileListContainerRect() {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const fileListContainerRect = fileListContainer.value.getBoundingClientRect();

    containerRect.value.width = windowWidth - fileListContainerRect.x;
    containerRect.value.height = windowHeight - fileListContainerRect.y;
  }

  onMounted(() => {
    setTimeout(() => {
      createFileListContainerRect();
    }, 200);

    window.addEventListener('resize', onWindowResize);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', onWindowResize);
    onWindowResize.cancel();

    selection.clear();
    filesStore.clearFiles();
  });


  const drawerAdjust = ref(0);
  useEventBus('drawer-adjust-picker').on(payload => {
    drawerAdjust.value = payload.width;
  })

</script>
