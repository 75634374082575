export const ALBUM_HIERARCHY_MAX_DEPTH = 15;
export const ALBUM_MAX_NAME_LENGTH = 255;
export const ALBUM_COPY_FILES_COUNT_LIMIT = 2000;

//todo: retire key in preference for value
export const ALBUM_PERMISSION_LAYERS = {
  private: {
    label: 'Private',
    help: 'only I can see',
    tooltip: 'Only I can see this',
    key: 'private',
    value: 'private',
    icon: 'i-ri-lock-fill',
    color: 'heroblue',
    iconOnlyClass: 'text-blue-400'
  },

  public: {
    label: 'Public',
    help: 'anyone can see',
    tooltip: 'Anyone can see this',
    key: 'public',
    value: 'public',
    icon: 'i-ri-earth-fill',
    color: 'salmon',
    iconOnlyClass: 'text-salmon'
  },

  family: {
    label: 'Connections',
    help: 'all my connections',
    tooltip: 'All Connections can see this',
    key: 'family',
    value: 'family',
    icon: 'i-ri-open-arm-fill',
    color: 'cyan',
    iconOnlyClass: 'text-teal'
  },

  group: {
    label: 'Groups',
    help: 'share with specific groups',
    tooltip: 'This is shared with Groups',
    key: 'group',
    value: 'group',
    icon: 'i-ri-group-fill',
    color: 'teal',
    iconOnlyClass: 'text-sage-700'
  }

};

export const ALBUM_LIST_CONTAINER_CLASS = 'grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 3xl:grid-cols-8';
export const ALBUM_LIST_GAP_CLASS = 'gap-3 lg:gap-5';
export const ALBUM_LIST_SECTION_SIZE = 120;

export const ALBUM_SORT_OPTIONS = [
  {
    label: 'Name (A > Z)',
    param: 'name_asc'
  },
  {
    label: 'Name (Z > A)',
    param: 'name_desc'
  },
  {
    label: 'Start Date (Ascending)',
    param: 'start_date_asc'
  },
  {
    label: 'Start Date (Descending)',
    param: 'start_date_desc'
  }
];

export const ALBUM_VISITED_SORT_OPTIONS = [
  {
    label: 'Most Recently Viewed',
    param: 'viewed_at_desc'
  },
  {
    label: 'Least Recently Viewed',
    param: 'viewed_at_asc'
  },
  ...ALBUM_SORT_OPTIONS
];

export const ALBUM_PRIVACY_FILTER_OPTIONS = [
  {
    label: 'All',
    param: null
  },
  {
    label: 'Private',
    param: 'private'
  },
  {
    label: 'Public',
    param: 'public'
  },
  {
    label: 'Connections',
    param: 'family'
  },
  {
    label: 'Groups',
    param: 'group'
  }
];

export const ALBUM_EVENTS = {
  navigationRefresh: 'album-nav-refresh'
};

export const ALBUM_SELECTION_COMPOSABLE_KEY = 'albums';
