<template>
  <section>
    <core-modal-header>{{isMove ? 'Move' : 'Add'}} to Album</core-modal-header>

    <core-modal-body>
      <p class="mb-2">
        <template v-if="isMulti">{{isMove ? 'Move' : 'Add'}} the <strong>{{count}} selected files</strong> to...</template>
        <template v-else>{{isMove ? 'Move' : 'Add'}} file <strong>{{name}}</strong> to...</template>
      </p>

      <album-navigator @select="save" show-create :disable-ids="[fromAlbumId]" allow-children-of-disabled />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
    </core-modal-footer>

  </section>

</template>

<script setup>
  const emit = defineEmits(['success']);

  const props = defineProps({
    files: Array,
    fromAlbumId: String,
    modal: Object
  });

  const filesStore = useFilesStore();
  const isMove = Boolean(props.fromAlbumId);
  const count = computed(() => props.files.length);
  const isMulti = computed(() => count.value > 1);
  const name = computed(() => props.files[0].name);

  async function save(album) {
    try {
      const ids = props.files.map(file => file.id);

      /*await Promise.all([
        filesStore.addFilesToContext({contextId: album.id, contextType: 'album', ids}),
        isMove ? filesStore.removeFilesFromContext({contextId: props.fromAlbumId, contextType: 'album', ids}) : null
      ]);*/

      await (isMove
        ? filesStore.moveFiles({
            ids,
            sourceContextType: FILE_CONTEXTS.album,
            sourceContextId: props.fromAlbumId,
            destinationContextType: FILE_CONTEXTS.album,
            destinationContextId: album.id
          })
        : filesStore.addFilesToContext({
            contextId: album.id,
            contextType: 'album',
            ids
          }));

      filesStore.invalidateFileDetails();

      useSuccessToast().add({
        id: 'files-added-to-album-success',
        timeout: 8_000,
        description: `Success! The <strong>${count.value} file(s)</strong> were ${isMove ? 'moved' : 'added'} to  <strong>${html2text(album.name)}</strong>.`,
        actions: [
          {
            label: 'View Album',
            click: () => {
              navigateTo(useBuildRoute().toAlbum({album}));
            }
          }
        ]
      });

      emit('success');

    } catch (e) {
      useErrorToast().add();
    }
  }

  function close() {
    props.modal.close();
  }

</script>
