<template>
  <div class="f-file_detail_audio flex-center" :style="featurePosition.style.value">
    <core-busy-dots v-if="isLoading" />

    <core-media-player
      v-else-if="currentState === STATES.playable"
      :src="fileUrl"
      type="audio"
      :is-active="isActive && !isTransitioning"
      @error="onAudioError"
      :width="featurePosition.style.value.width"
      :height="featurePosition.style.value.height"
    />

    <file-detail-non-viewable v-else>
      <template #image>
        <div>
          <u-icon :name="currentState.icon" class="text-white text-5xl" :class="currentState.spin ? 'animate-spin' : null" />
        </div>
      </template>

      <template #description>
        <h3>{{currentState.title}}</h3>
        <p v-if="file.user">{{currentState.desc}}</p>
      </template>
    </file-detail-non-viewable>

  </div>
</template>

<script setup>

  const props = defineProps({
    file: Object,
    availableSpace: Object,
    isActive: Boolean,
    isTransitioning: Boolean
  });

  const STATES = {
    playable: 'PLAYABLE',
    errored: {
      icon: 'i-ri-error-warning-fill',
      title: 'Playback Unavailable',
      desc: 'Playback is unavailable because something went wrong while processing this file. Your original file is safe, and can be downloaded from the link above.'
    },
    playbackErrored: {
      icon: 'i-ri-error-warning-fill',
      title: 'Playback Error',
      desc: 'Something went wrong and we could not play your audio file.'
    },
    processing: {
      spin: true,
      icon: COMMON_ICONS.loading,
      title: 'Audio Processing',
      desc: 'Preparing your audio file for playback. Please wait.'
    }
  };

  const isPlaybackErrored = ref(false);
  const isLoading = computed(() => !(props.file.is && props.file.is.detail));
  const fileUrl = computed(() => props.file.preview_url);
  const currentState = computed(() => {
    if (props.file.is.errored) {
      return STATES.errored;
    }

    if (isPlaybackErrored.value) {
      return STATES.playbackErrored;
    }

    if (props.file.is.processing) {
      return STATES.processing;
    }

    return STATES.playable;
  });

  const featurePosition = useDetailFeaturePosition({
    featureDimensions: computed(() => {
      if (currentState.value === STATES.playable) {
        return {
          width: 800,
          height: 500
        };
      }

      return {
        width: props.availableSpace.width,
        height: props.availableSpace.height
      };
    }),
    availableSpace: computed(() => props.availableSpace)
  });

  function onAudioError() {
    isPlaybackErrored.value = true;
  }

</script>
