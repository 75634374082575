export function useDetailFeaturePosition({featureDimensions, availableSpace}) {
  const coords = computed(() => {
    if (!availableSpace?.value) {
      return null;
    }

    let {width, height} = featureDimensions.value;
    const aspectRatio = height / width;

    if (height > availableSpace.value.height) {
      height = availableSpace.value.height;
      width = height / aspectRatio;
    }

    if (width > availableSpace.value.width) {
      width = availableSpace.value.width;
      height = width * aspectRatio;
    }

    const top = (availableSpace.value.height - height) / 2;
    const left = (availableSpace.value.width - width) / 2;

    return {
      top: Math.round(top),
      left: Math.round(left),
      width: Math.round(width),
      height: Math.round(height)
    };
  });

  return {
    coords,
    style: computed(() => ({
      top: `${coords.value.top}px`,
      left: `${coords.value.left}px`,
      width: `${coords.value.width}px`,
      height: `${coords.value.height}px`
    }))
  };
}
