import { default as indextDJU55YGYWMeta } from "/tmp/build_3d62f433/pages/connections/index.vue?macro=true";
import { default as indexoJgWwQ2iNWMeta } from "/tmp/build_3d62f433/pages/delete-bin/index.vue?macro=true";
import { default as _91share_token_93DzFpP99gA1Meta } from "/tmp/build_3d62f433/pages/external/embed/[user_id]/[file_id]/[share_token].vue?macro=true";
import { default as _91file_id_931bkbovkdqwMeta } from "/tmp/build_3d62f433/pages/gallery/[file_id].vue?macro=true";
import { default as galleryjLnXAquVZzMeta } from "/tmp/build_3d62f433/pages/gallery.vue?macro=true";
import { default as indexUmTeYOKUwcMeta } from "/tmp/build_3d62f433/pages/groups/index.vue?macro=true";
import { default as _91_91id_93_930BjAa1O479Meta } from "/tmp/build_3d62f433/pages/impersonate/[[id]].vue?macro=true";
import { default as log_45inFrHEJph9xeMeta } from "/tmp/build_3d62f433/pages/log-in.vue?macro=true";
import { default as log_45out4BpCIvlQlOMeta } from "/tmp/build_3d62f433/pages/log-out.vue?macro=true";
import { default as indexAmKTgIjJbQMeta } from "/tmp/build_3d62f433/pages/notifications/index.vue?macro=true";
import { default as projectsQxf7Xky4IlMeta } from "/tmp/build_3d62f433/pages/projects.vue?macro=true";
import { default as _91file_id_93SQTs062DbvMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_932KXzeB7ga3Meta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue?macro=true";
import { default as index9OZu9okh7eMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/index.vue?macro=true";
import { default as visitedbszmCxxf3lMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/visited.vue?macro=true";
import { default as _91file_id_932imHMMWj3yMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93coRivJdVD1Meta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue?macro=true";
import { default as _91_91share_token_93_93A0Y22BCWFnMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue?macro=true";
import { default as indexPFqasTk55AMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/index.vue?macro=true";
import { default as _91file_id_93nackZ5vmcRMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93J5xTnbrgydMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexfeoukxLCxpMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/people/index.vue?macro=true";
import { default as _91file_id_93SrgtrkHmioMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/print-projects/[file_id].vue?macro=true";
import { default as print_45projectsCYdzVGwoJSMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/print-projects.vue?macro=true";
import { default as _91file_id_93XeWlp1STnaMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue?macro=true";
import { default as _91_91share_token_93_93goDRN2kVBiMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue?macro=true";
import { default as indexBQeWjng5iFMeta } from "/tmp/build_3d62f433/pages/users/[user_id_or_slug]/tags/index.vue?macro=true";
import { default as welcomevNHgQqpgjdMeta } from "/tmp/build_3d62f433/pages/welcome.vue?macro=true";
import { default as component_45stubgyGBdtAmCoMeta } from "/tmp/build_3d62f433/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubgyGBdtAmCo } from "/tmp/build_3d62f433/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "connections",
    path: "/connections",
    meta: indextDJU55YGYWMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/connections/index.vue")
  },
  {
    name: "delete-bin",
    path: "/delete-bin",
    meta: indexoJgWwQ2iNWMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/delete-bin/index.vue")
  },
  {
    name: "external-embed-user_id-file_id-share_token",
    path: "/external/embed/:user_id()/:file_id()/:share_token()",
    component: () => import("/tmp/build_3d62f433/pages/external/embed/[user_id]/[file_id]/[share_token].vue")
  },
  {
    name: "gallery",
    path: "/gallery",
    meta: galleryjLnXAquVZzMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/gallery.vue"),
    children: [
  {
    name: "gallery-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_3d62f433/pages/gallery/[file_id].vue")
  }
]
  },
  {
    name: "groups",
    path: "/groups",
    meta: indexUmTeYOKUwcMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/groups/index.vue")
  },
  {
    name: "impersonate-id",
    path: "/impersonate/:id?",
    component: () => import("/tmp/build_3d62f433/pages/impersonate/[[id]].vue")
  },
  {
    name: "log-in",
    path: "/log-in",
    component: () => import("/tmp/build_3d62f433/pages/log-in.vue")
  },
  {
    name: "log-out",
    path: "/log-out",
    component: () => import("/tmp/build_3d62f433/pages/log-out.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    meta: indexAmKTgIjJbQMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/notifications/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: projectsQxf7Xky4IlMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/projects.vue")
  },
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/albums/:album_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-albums-album_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/[album_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-albums",
    path: "/users/:user_id_or_slug()/albums",
    meta: index9OZu9okh7eMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/index.vue")
  },
  {
    name: "users-user_id_or_slug-albums-visited",
    path: "/users/:user_id_or_slug()/albums/visited",
    meta: visitedbszmCxxf3lMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/albums/visited.vue")
  },
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/containers/:container_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-containers-container_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/containers/[container_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-files-file_id-share_token",
    path: "/users/:user_id_or_slug()/files/:file_id()/:share_token?",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/files/[file_id]/[[share_token]].vue")
  },
  {
    name: "users-user_id_or_slug",
    path: "/users/:user_id_or_slug()",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/index.vue")
  },
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/people/:person_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-people-person_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/people/[person_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-people",
    path: "/users/:user_id_or_slug()/people",
    meta: indexfeoukxLCxpMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/people/index.vue")
  },
  {
    name: "users-user_id_or_slug-print-projects",
    path: "/users/:user_id_or_slug()/print-projects",
    meta: print_45projectsCYdzVGwoJSMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/print-projects.vue"),
    children: [
  {
    name: "users-user_id_or_slug-print-projects-file_id",
    path: ":file_id()",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/print-projects/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token",
    path: "/users/:user_id_or_slug()/tags/:tag_id_or_slug()/:share_token?",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]].vue"),
    children: [
  {
    name: "users-user_id_or_slug-tags-tag_id_or_slug-share_token-files-file_id",
    path: "files/:file_id()",
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/tags/[tag_id_or_slug]/[[share_token]]/files/[file_id].vue")
  }
]
  },
  {
    name: "users-user_id_or_slug-tags",
    path: "/users/:user_id_or_slug()/tags",
    meta: indexBQeWjng5iFMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/users/[user_id_or_slug]/tags/index.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomevNHgQqpgjdMeta || {},
    component: () => import("/tmp/build_3d62f433/pages/welcome.vue")
  },
  {
    name: component_45stubgyGBdtAmCoMeta?.name,
    path: "/",
    component: component_45stubgyGBdtAmCo
  },
  {
    name: component_45stubgyGBdtAmCoMeta?.name,
    path: "/library",
    component: component_45stubgyGBdtAmCo
  },
  {
    name: component_45stubgyGBdtAmCoMeta?.name,
    path: "/static/images/forever-album-no-cover.png",
    component: component_45stubgyGBdtAmCo
  }
]