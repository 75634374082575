<template>
  <div
    id="selection-toolbar"
    class="fixed lg:absolute top-0 left-0 right-0 bg-white z-20 transition-all duration-300 border-b border-l border-r border-gray-200 shadow"
    :class="hasAny ? 'opacity-1 translate-y-0': 'opacity-0 pointer-events-none -translate-y-full'"
  />
</template>

<script setup>
  const props = defineProps({
    selectionComposable: Object
  });

  const selection = props.selectionComposable || useSelection();
  const {hasAny} = selection;
</script>
