import {defineStore} from 'pinia';

function searchMap(map, id) {
  let foundItem = null;

  map.forEach(value => {
    const item = value.find && value.find(element => element.id === id);
    if (item) {
      foundItem = item;
    }
  });

  return foundItem;
}

export const makeAlbumsStore = (id = 'albumsStore') => defineStore(id, {
  state: () => ({
    albums: null,
    hasMore: false,
    cursor: undefined,
    collectionDescriptor: null,
    album: null,

    nestedAlbums: new Map(),
    tokenAlbums: new Map()
  }),

  actions: {
    async getAlbums({nextPage, reload, userId, search, /*parentAlbumId,*/ shareToken, privacy, privacyFilter, sharedOnly, root, order} = {}) {
      if (!nextPage && !reload) {
        this.clearAlbums();
        this.collectionDescriptor = {userId, search, /*parentAlbumId,*/ shareToken, privacy, privacyFilter, sharedOnly, root, order};
      }

      if (reload) {
        this.albums = null;
        this.hasMore = false;
        this.cursor = undefined;
      }

      const method = this.collectionDescriptor.sharedOnly ? 'getSharedAlbums' : 'getAlbums';
      const data = await useAlbumApi()[method]({
        cursor: this.cursor,
        ...this.collectionDescriptor
      });

      this.albums = [...(this.albums || []), ...data.albums];
      this.hasMore = data.albums.length === data.per_page;
      this.cursor = data.next_cursor;

      return this.albums;
    },

    async getNestedAlbums({userId, parentAlbumId, shareToken, refresh}) {
      if (this.nestedAlbums.get(parentAlbumId) && !refresh) {
        return;
      }

      this.nestedAlbums.set(parentAlbumId, {isPending: true});

      const albumApi = useAlbumApi();
      let data = {};
      let cursor;

      while (data.albums?.length === data.per_page) {
        data = await albumApi.getAlbums({cursor, userId, parentAlbumId, shareToken});
        const nestedItem = this.nestedAlbums.get(parentAlbumId);
        const loadedAlbums = nestedItem.isPending ? [] : nestedItem;
        this.nestedAlbums.set(parentAlbumId, [...loadedAlbums, ...data.albums]);
        cursor = data.next_cursor;
      }
    },

    async getTokenAlbum({album, shareToken}) {
      if (this.tokenAlbums.get(shareToken)) {
        return this.tokenAlbums.get(shareToken);
      }

      if (album.share_token === shareToken) {
        this.tokenAlbums.set(shareToken, album);
        return album;
      }

      const requests = [album.parent_album.id, ...album.parent_album.ancestor_ids].map(async (id) => {
        try {
          return await useAlbumApi().getAlbum({id, userId: album.user.id, shareToken});
        } catch (e) {

          if (e.status !== 403) {
            throw e;
          }
        }
      });
      const ancestors = await Promise.all(requests);
      const tokenAlbum = ancestors.find(ancestor => ancestor?.share_token === shareToken);

      this.tokenAlbums.set(shareToken, tokenAlbum);

      return tokenAlbum;
    },

    clearAlbums() {
      this.albums = null;
      this.hasMore = false;
      this.cursor = undefined;
      this.collectionDescriptor = null;
    },

    clearNestedAlbums() {
      this.nestedAlbums.clear();
    },

    async getAlbumsOne({id, userId, shareToken}) {
      const album = await useAlbumApi().getAlbum({id, userId, shareToken});
      const updateIndex = this.albums?.findIndex(item => item.id === album.id);

      if (updateIndex > -1) {
        this.albums.splice(updateIndex, 1, album);
      }

      let nestedAlbum = searchMap(this.nestedAlbums, album.id)
      if (nestedAlbum) {
        nestedAlbum = Object.assign(nestedAlbum, album);
      }

      if (this.tokenAlbums.get(album.id)) {
        this.tokenAlbums.set(album.id, album);
      }

      return album;
    },

    async moveAlbums({albums, destId, sourceId}) {
      const albumIds = albums.map(a => a.id);

      await useAlbumApi().moveAlbums({albumIds, parentId: destId});

      if (albumIds.length === 1 && this.album?.id === albumIds[0]) {
        await this.refreshCurrentAlbum();
      }

      const requests = [];

      if (destId) {
        requests.push(this.getAlbumsOne({id: destId}));

        if (this.nestedAlbums.get(destId)) {
          requests.push(this.getNestedAlbums({parentAlbumId: destId, refresh: true}));
        }
      } else {
        //albums were moved to root
        await this.getAlbums({reload: true});
      }

      if (sourceId) {
        requests.push(this.getAlbumsOne({id: sourceId}));

        if (this.nestedAlbums.get(sourceId)) {
          requests.push(this.getNestedAlbums({parentAlbumId: sourceId, refresh: true}));
        }
      } else {
        //albums were moved from root
        this.albums = this.albums?.filter(a => !albumIds.includes(a.id));
      }

      return Promise.all(requests);
    },

    async getAlbum({id, userId, shareToken, addedSince}) {
      const albumApi = useAlbumApi();

      const [album, count] = await Promise.all([
        albumApi.getAlbum({id, userId, shareToken}),
        addedSince ? albumApi.getAlbumFilesAddedSince({id, userId, addedSince}) : null
      ]);

      if (addedSince) {
        album.files_added = count;
        album.addedSince = addedSince;
      }

      album.user.isStorageFull = album.user.storage_used >= album.user.storage_capacity;
      album.isOwnedByUser = useUserContext({entity: album}).isEntityOwner.value;

      if (this.album?.id === id) {
        Object.assign(this.album, album);
      } else {
        this.album = album;
      }

      return this.album;
    },

    async updateAlbum({id, updates}) {
      const updatedAlbum = await useAlbumApi().updateAlbum({albumId: id, payload: updates});

      if (this.album && id === this.album.id) {
        Object.assign(this.album, updatedAlbum);
      }

      const listAlbum = this.albums?.find(a => a.id === id);
      if (listAlbum) {
        Object.assign(listAlbum, updatedAlbum);
      }

      let nestedAlbum = searchMap(this.nestedAlbums, updatedAlbum.id)
      if (nestedAlbum) {
        nestedAlbum = Object.assign(nestedAlbum, updatedAlbum);
      }

      return updatedAlbum;
    },

    async createAlbum({payload}) {
      const album = await useAlbumApi().createAlbum(payload);
      const parentId = album.parent_album?.id;

      if (parentId) {
        if (this.nestedAlbums.has(parentId)) {
          this.nestedAlbums.get(parentId).push(album);
        } else {
          this.nestedAlbums.set(parentId, [album]);
        }

        if (this.album && this.album.id === parentId) {
          this.album.albums_count = this.album.albums_count + 1;
        }

        const parentAlbum = this.albums?.find(a => a.id === parentId) || searchMap(this.nestedAlbums, parentId)
        if (parentAlbum) {
          parentAlbum.albums_count++;
        }
      }

      return album;
    },

    async destroyAlbums({albums}) {
      const ids = albums.map(a => a.id);
      await useAlbumApi().destroyAlbums({ids});

      this.albums = this.albums?.filter(a => !ids.includes(a.id));
      if (this.album && !ids.includes(this.album.id)) {
        this.album.albums_count = this.album.albums_count - ids.length;
      }

      this.nestedAlbums.forEach((value, key) => {
        this.nestedAlbums.set(key, value.filter(a => !ids.includes(a.id)));
      });

      const parentIds = albums.reduce((ids, album) => {
        if (album.parent_album) {
          ids.add(album.parent_album.id);
        } else if (album.ancestor_ids?.length) {
          ids.add(album.ancestor_ids[album.ancestor_ids.length - 1]);
        }

        return ids;
      }, new Set());

      const cleanedUpParentIds = Array.from(parentIds).filter(id => !ids.includes(id));

      await Promise.all(cleanedUpParentIds.map(parentId => this.getAlbumsOne({id: parentId})));
    },

    async disableShareToken({id}) {
      await useAlbumApi().disableShareToken({id});

      if (this.album) {
        this.album.share_token = null;
        this.album.collaborative = false;
      }

      this.albums?.forEach(item => {
        if (item.id === id) {
          item.share_token = null;
          item.collaborative = false;
        }
      });

    },

    async refreshCurrentAlbum() {
      if (this.album) {
        this.album = await this.getAlbum({id: this.album.id});
      }
    },

    async resetShareToken({id}) {
      const newToken = await useAlbumApi().resetShareToken({id});

      if (this.album) {
        this.album.share_token = newToken;
      }

      this.albums.forEach(item => {
        if (item.id === id) {
          item.share_token = newToken;
        }
      });
    },

    async removeVisitedAlbums({ids}) {
      await useAlbumApi().removeVisitedAlbums({ids});
      this.albums = this.albums?.filter(a => !ids.includes(a.sharedItemId));
    }
  }
});

export const useAlbumsStore = makeAlbumsStore();
