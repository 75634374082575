<template>
  <div class="flex gap-2 pt-px">

    <client-only>
      <div class="flex sm:flex-1 gap-2 items-center">
        <transition-group name="f-fade">
          <template v-if="!albumAddedSinceMode && !isSearchMode">
            <u-tooltip :prevent="!areFiltersApplied" :ui="{wrapper: 'block', base: 'text-base h-auto text-text-light'}">

              <template #text>
                <p class="font-semibold">Filters</p>
                <p class="text-sm">You have {{filterCount}} filters applied.</p>
              </template>

              <core-responsive-button
                v-if="hasFilterButton"
                icon="i-ri-equalizer-line"
                :variant="areFiltersApplied ? 'solid': 'outline'"
                :color="areFiltersApplied ? 'heroblue': 'charcoal'"
                @click="toggle"
              >Filters</core-responsive-button>
            </u-tooltip>


            <core-responsive-button
              v-if="areFiltersApplied"
              :icon="COMMON_ICONS.close"
              trailing
              variant="outline"
              color="charcoal"
              @click="filesStore.clearFilters"
              label="Clear Filters"
            >Clear Filters ({{filterCount}})</core-responsive-button>
            <span class="hidden md:inline text-sm text-gray-500 italic" v-if="areFiltersApplied && !isLoadingFiles">{{filesCountFallback}} Results</span>
          </template>
          <div v-else-if="isSearchMode" class="items-center gap-3 hidden md:flex">
            <u-button
              :icon="COMMON_ICONS.close"
              variant="outline"
              color="charcoal"
              @click="search"
              label="Clear Search"
            />
            <p class="font-semibold">Your search for "{{searchTerm}}" returned {{files.length ? '' : 'no'}} results.</p>
          </div>
          <template v-else>
            <template v-if="albumAddedSinceMode">
              <u-button color="charcoal"  @click="viewFullAlbum" label="View Full Album" />
            </template>
          </template>
        </transition-group>
      </div>

      <div class="flex flex-1 sm:flex-0 items-center justify-end gap-2">
        <template v-if="!albumAddedSinceMode">
<!--          <core-sort-select-menu v-if="!isSearchMode" v-model="sort" :options="sortOptions" />-->
          <core-search-input
            v-if="!collectionDescriptor?.contextType"
            :model-value="searchTerm"
            placeholder="Name & Description"
            @search="search"
            @clear="search"
            :loading="isSearching"
          />
        </template>

        <div class="flex gap-1.5">
          <u-button-group size="sm">
            <u-button
              icon="i-material-symbols-background-grid-small-sharp"
              :variant="gridSize === FILE_GRID_SIZES.sm ? 'solid' : 'outline'"
              color="charcoal"
              @click="setGridSize(FILE_GRID_SIZES.sm)"
              :ui="{icon: {base: 'scale-125'}}"
            />
            <u-button
              icon="i-oi-grid-three-up"
              :variant="gridSize === FILE_GRID_SIZES.md ? 'solid' : 'outline'"
              color="charcoal"
              @click="setGridSize(FILE_GRID_SIZES.md)"
            />
            <u-button
              icon="i-oi-grid-two-up"
              :variant="gridSize === FILE_GRID_SIZES.lg ? 'solid' : 'outline'"
              color="charcoal"
              @click="setGridSize(FILE_GRID_SIZES.lg)"
            />
          </u-button-group>

        </div>
      </div>

      <u-slideover v-model="showFilterPanel" side="left" :ui="filterPanelConfig">
        <file-filter-panel :files-store-id="filesStoreId" @toggle="toggle" @update-sort="updateSort" />
      </u-slideover>

    </client-only>
  </div>
</template>

<script setup>
  import {storeToRefs} from 'pinia';
  import {makeFilesStore} from "~/stores/files.js";

  const emit = defineEmits(['update:sort']);
  const props = defineProps({
    /*sortOptions: {
      type: Array,
      default: SORT_OPTIONS
    },*/
    isInPicker: Boolean,
    filesStoreId: String,
    hasFilterButton: {
      type: Boolean,
      default: true
    }
  });

  const grid = useFileListGrid();

  const filesStore = props.filesStoreId
    ? makeFilesStore(props.filesStoreId)()
    : useFilesStore();

  const {areFiltersApplied, collectionDescriptor, filesCount, filesCountFallback, filterCount, files, isLoadingFiles} = storeToRefs(filesStore);

  const albumAddedSinceMode = computed(() => Boolean(collectionDescriptor.value?.addedSince));

  /*const sort = computed({
    get() {
      return collectionDescriptor.value?.order || FILE_DEFAULT_SORT_OPTION;
    },
    set(value) {
      filesStore.getFiles({
        ...collectionDescriptor.value,
        order: value
      });

      emit('update:sort', value);
    }
  });*/

  function updateSort(value) {
    emit('update:sort', value);
  }

  const searchTerm = ref('');
  const isSearching = ref(false);
  const isSearchMode = computed(() => Boolean(searchTerm.value));

  watch(() => collectionDescriptor.value?.search, newVal => (searchTerm.value = newVal || ''), {immediate: true});

  async function search({q = ''} = {}) {
    try {
      isSearching.value = true;
      searchTerm.value = q;

      await filesStore.getFiles({
        ...collectionDescriptor.value,
        search: searchTerm.value
      });
    } catch (e) {
      useErrorToast().add();
    } finally {
      isSearching.value = false;
    }
  }

  const gridSize = computed(() => grid.size.value);
  function setGridSize(size) {
    grid.setSize(size);
  }

  const showFilterPanel = ref(false);
  function toggle() {
    showFilterPanel.value = !showFilterPanel.value;
  }

  const router = useRouter();
  function viewFullAlbum() {
    router.push({
      query: {
        since: undefined
      }
    });
  }

  watch(() => showFilterPanel.value, newVal => {
    const eventBus = props.isInPicker ? 'drawer-adjust-picker' : 'drawer-adjust-main';
    useEventBus(eventBus).emit({
      width: newVal ? 410 : 0
    });
  });
  const filterPanelConfig = {
    overlay: {
      base: 'fixed inset-0 transition-opacity',
      background: 'bg-black/25'
    },
    width: `max-w-[410px]`
  }

</script>
