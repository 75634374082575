<template>
  <div v-if="peopleToBox?.length" class="absolute inset-0 z-10 pointer-events-none" ref="peopleFacesEl">
    <div
      v-for="person in peopleToBox"
      @contextmenu.prevent
      class="absolute flex-center rounded hover:z-10 transition-all shadow ring-2 opacity-0 hover:opacity-100 hover:scale-105 ring-white/60 hover:ring-white pointer-events-auto"
      :class="{
        'opacity-100': (person.person_file_id === highlightPerson.current.value?.person_file_id) || showAllBox,
        '!ring-white scale-105': person.person_file_id === highlightPerson.current.value?.person_file_id
      }"
      :style="{
        top: person.boundingBox.y,
        left: person.boundingBox.x,
        width: person.boundingBox.w,
        height: person.boundingBox.h
      }"
      @mouseenter="toggleBoundingBox(person)"
      @mouseleave="toggleBoundingBox(person, true)"
    />
  </div>
</template>

<script setup>
  const props = defineProps({
    file: {
      type: Object,
      required: true
    },
    scale: {
      type: Number,
      default: 1
    },
    rotation: {
      type: Number,
      default: 0
    }
  });

  const fileContext = inject('fileContext');
  const filesStore = useFilesStore();
  const highlightPerson = usePersonLightboxHighlight();

  const peopleFacesEl = ref();
  const peopleToBox = computed(() => props.file.people
    ?.filter(p => p.boundingBox)
    .sort((a, b) => a.bounding_box_left - b.bounding_box_left || a.bounding_box_top - b.bounding_box_top)
  );

  const showAllBox = ref(false);
  const boundingBoxToggleAllEvent = useEventBus('core-face-bounding-box-toggle-all');

  function toggleBoundingBox(person, forceOff) {
    highlightPerson.set(forceOff ? null : person);
  }

  function toggleBoundingBoxAll() {
    showAllBox.value = !showAllBox.value;
  }

  onMounted(() => {
    boundingBoxToggleAllEvent.on(toggleBoundingBoxAll);
  });

  onBeforeUnmount(() => {
    boundingBoxToggleAllEvent.off(toggleBoundingBoxAll);
  });

</script>
