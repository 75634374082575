import {useLocalApi} from '~/composables/core/http.js';
import {LayoutModalAppUpdate} from '#components';

export default defineNuxtPlugin(() => {
  usePricingStore().getPromotions();

  /* the below code compares a server version with a client's cached version, and recommends a refresh if they are different */
  let intervalId;
  const pollInterval = 60_000;
  const {appVersion: localVersion} = useRuntimeConfig().public;

  async function checkVersion() {
    const {v: apiVersion} =  await useLocalApi().fetch('/version');
    console.log(`Checking Version: Local: ${localVersion}, Server: ${apiVersion}`);

    if (localVersion !== apiVersion && !useFModals().areModalsOpen.value) {
      useFModal({
        id: 'app-update',
        size: 'md',
        clickToClose: false,
        showClose: false,
        escToClose: false,
        component: LayoutModalAppUpdate,
        onOpen: () => clearInterval(intervalId),
        onClose: () => intervalId = setInterval(checkVersion, pollInterval)
      }).open();
    }
  }

  intervalId = setInterval(checkVersion, pollInterval);
});
