<template>
  <div class="file-non-viewable-feature flex-center flex-col h-full w-full text-center bg-blue-500/20 p-[10%]">
    <slot name="image" />
    <slot name="description" />
  </div>
</template>

<style scoped lang="scss">
  .file-non-viewable-feature {
    :deep(p) {
      @apply text-white text-lg;
    }

    :deep(h3) {
      @apply text-white my-5 text-3xl font-semibold;
    }

    :deep(a) {
      @apply text-white underline;
    }
  }
</style>
