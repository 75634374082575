
const current = ref(null);

export function usePersonLightboxHighlight() {

  return {
    set(person) {
      current.value = person;
    },
    current
  }
}
