<template>
  <div :class="areNestedExpanded && areNestedLoaded && !disableNesting && isActiveTopLevel ? 'bg-slate-200/75 py-1 my-1.5 mx-1 rounded transition-all' : ''">
    <nuxt-link
      :data-album-id="item.id"
      exact-active-class="font-semibold !bg-slate-300/75 text-heroblue is-exact-active"
      class="group leading-none text-charcoal no-underline hover:bg-slate-100 mx-1 flex gap-2 items-center rounded min-h-8"
      :class="[
        !disableNesting && level === 1 ? 'pl-3 pr-1.5' : 'px-1.5',
        level > 1 ? 'py-1.5 ml-5' : 'py-1 my-0',
      ]"
      :to="useBuildRoute().toAlbum({album: item, userId, shareToken})"
      @click="toggleNestedAlbums(item.id, true, shareToken)"
    >

      <div class="relative flex">
        <button
          @click.stop.prevent="toggleNestedAlbums(item.id, false, shareToken)"
          :class="level === 1 ? '-left-[14.5px]' : '-left-[20px]'"
          class="flex-center absolute h-full text-charcoal-400"
          v-if="item.albums_count && !disableNesting"
        >
          <u-icon v-if="isLoadingNested" :name="COMMON_ICONS.loading" class="text-base animate-spin" />
          <u-icon v-else-if="areNestedExpanded" name="i-ri-arrow-down-s-fill" class="text-base" />
          <u-icon v-else name="i-ri-arrow-right-s-fill" class="text-base" />
        </button>
        <album-avatar v-if="showCoverPhotos" :album="item" size="md" :ui="{size: {md: avatarSize}, icon: {size: {md: iconSize}}}" />
      </div>
      <span class="flex-1 flex flex-col min-w-0">
        <span class="text-sm leading-none break-long-string group-[.is-exact-active]:text-heroblue">{{item.name}}</span>
        <span class="flex gap-1 items-center font-normal">
          <span class="text-gray-500 group-[.is-exact-active]:text-heroblue text-2xs mt-1" v-if="item.albums_count">{{item.albums_count}} Nested</span>
        </span>
      </span>
      <span class="text-gray-400 group-[.is-exact-active]:text-heroblue text-2xs">{{item.files_count}}</span>
    </nuxt-link>

    <div v-if="areNestedLoaded && !disableNesting" class="pl-3" v-show="areNestedExpanded">
      <album-navigation-item
        v-for="album in itemsNestedAlbums"
        :share-token="shareToken"
        :key="album.id"
        :item="album"
        :level="level + 1"
        :show-cover-photos="showCoverPhotos"
      />
    </div>
  </div>


</template>

<script setup>
  const props = defineProps({
    item: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      default: 1
    },
    shareToken: String,
    disableNesting: Boolean,
    showCoverPhotos: {
      type: Boolean,
      default: true
    }
  });

  const {user_id_or_slug: userId} = useRoute().params;

  const avatarSize = computed(() => {
    switch (props.level) {
      case 1:
        return 'h-[38px] w-[38px]';
      case 2:
        return 'h-[32px] w-[32px]';
      case 3:
      case 4:
      case 5:
      case 6:
        return 'h-[30px] w-[30px]';
      default:
        return 'h-[24px] w-[24px]';
    }
  });

  const iconSize = computed(() => {
    switch (props.level) {
      case 1:
        return 'h-4 w-4';
      case 2:
        return 'h-3 w-3';
      default:
        return 'w-3 h-3';
    }
  });

  const expandParentsEvent = useEventBus('album-navigation-expand');
  const albumsStore = useAlbumsStore();
  const {nestedAlbums, album} = storeToRefs(albumsStore);
  const itemsNestedAlbums = computed(() => nestedAlbums.value.get(props.item.id));
  const isLoadingNested = computed(() => itemsNestedAlbums.value?.isPending);
  const areNestedLoaded = computed(() =>  itemsNestedAlbums.value && (!itemsNestedAlbums.value.isPending || itemsNestedAlbums.value.error));
  const areNestedExpanded = ref(props.item.id === album.value.id);

  const isActiveTopLevel = computed(() => {
    if (props.level === 1 && props.item.albums_count) {
      let rootAlbumId;
      if (album.value.parent_album) {
        rootAlbumId = album.value.parent_album.ancestor_ids.length
          ? album.value.parent_album.ancestor_ids[0]
          : album.value.parent_album.id;
      } else {
        rootAlbumId = album.value.id;
      }
      return props.item.id === rootAlbumId;
    }
  });

  watch(areNestedLoaded, newVal => {
    if (newVal) {
      areNestedExpanded.value = true;
    }
  });

  watch(album, (newVal, oldVal) => {
    if (!areNestedExpanded.value && newVal?.id === props.item.id && newVal?.id !== oldVal?.id) {
      toggleNestedAlbums(props.item.id, true, props.shareToken);
    }
  });

  async function toggleNestedAlbums(parentAlbumId, stayOpen, shareToken) {
    if (props.disableNesting) {
      return;
    }

    if (stayOpen) {
      areNestedExpanded.value = true;
    } else {
      areNestedExpanded.value = !areNestedExpanded.value;
    }

    if (areNestedExpanded.value && !areNestedLoaded.value) {
      try {
        if (nestedAlbums.value.get(parentAlbumId) && !stayOpen) {
          nestedAlbums.value.delete(parentAlbumId);
        } else if (!nestedAlbums.value.get(parentAlbumId)) {
          await albumsStore.getNestedAlbums({parentAlbumId, userId: props.item.user_id, shareToken});
        }
      } catch (e) {
        useErrorToast().add();
      }
    }
  }

  function onExpandParents(ids) {
    if (ids.includes(props.item.id)) {
      areNestedExpanded.value = true;
    }
  }

  onMounted(async () => {
    expandParentsEvent.once(onExpandParents);

    await nextTick();

    if (album.value.parent_album && props.item.slug === album.value.slug) {
      expandParentsEvent.emit([album.value.parent_album.id, ...album.value.parent_album.ancestor_ids]);
    }
  });

  onBeforeUnmount(() => {
    expandParentsEvent.off(onExpandParents);
  });

</script>
