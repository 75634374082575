<template>
  <section>
    <core-modal-header>Choose an Album</core-modal-header>

    <core-modal-body>
      <album-navigator
        @select="select"
        :show-create="showCreate"
        :disable-ids="disableIds"
        :allow-children-of-disabled="allowChildrenOfDisabled"
      />
    </core-modal-body>

    <core-modal-footer>
      <u-button color="charcoal" variant="soft" @click="close">Cancel</u-button>
    </core-modal-footer>
  </section>
</template>

<script setup>

    const emit = defineEmits(['select']);
    const props = defineProps({
      disableIds: Array,
      showCreate: Boolean,
      allowChildrenOfDisabled: Boolean,
      modal: Object
    });

    function select(album) {
      emit('select', album);
    }

    function close() {
      props.modal.close();
    }

</script>
