<template>
  <div class="bg-slate-100/80 h-dvh">
    <div class="max-w-[2000px] mx-auto bg-white transition-transform border-x border-charcoal-200">
      <nuxt-loading-indicator />
      <div class="flex flex-col h-dvh">
        <header class="bg-white flex items-center p-2 lg:p-4 gap-3 bg-gradient-to-b from-ice to-white border-b border-charcoal-200 ">
          <nuxt-link class="block flex-1" to="/" external>
            <img alt="FOREVER® Logo" src="https://forever-mars-ui.s3.amazonaws.com/images/forever-logo-v2.svg" class="h-6 lg:h-8" />
          </nuxt-link>
          <div class="flex items-center gap-3">
            <span class="hidden lg:inline text-sm">Need an account?</span>
            <u-button variant="link" size="lg" :padded="false" @click="signUp">
              <span>Sign up<span class="hidden sm:inline"> for free</span></span>
            </u-button>
            <u-button
              :icon="COMMON_ICONS.signIn"
              @click="signIn"
              class="no-underline"
              label="Login"
            />
          </div>
        </header>

        <div class="flex-1 flex overflow-hidden gap-0 lg:gap-4 p-2 lg:p-4 !pb-0" :class="showAlbumsNav ? 'lg:!pl-0' : null">

          <nuxt-page class="h-full flex-1 order-2 overflow-hidden" />

          <!--column: contextual drawer-->
          <div
            ref="contextNav"
            class="transition-all ease-in-out delay-200 fixed z-10 lg:relative bg-white lg:h-[calc(100%+theme(space.4))] -mt-4 inset-0 flex-shrink-0"
            v-if="showAlbumsNav"
            :class="contextNavClass"
          >

            <client-only>
              <transition name="f-fade">
                <div v-if="!isContextNavCollapsed">
                  <div class="bg-black/50 fixed inset-0 lg:hidden z-10" @click.stop="isContextNavCollapsed = true" />
                  <div class="absolute z-20 inset-0 bg-white">
                    <album-navigation />
                  </div>
                </div>
              </transition>

              <button class="flex-center absolute z-30 bottom-3 rounded-r-lg border border-gray-300 border-l-0 w-10 h-10 translate-x-full bg-white/80 shadow" :class="isContextNavCollapsed ? 'right-0' : '-right-px'" @click.stop="isContextNavCollapsed = !isContextNavCollapsed">
                <u-tooltip :text="isContextNavCollapsed ? 'Maximize Menu' : 'Minimize Menu'" :popper="{placement: 'right'}">
                  <u-icon
                    :name="isContextNavCollapsed ? 'i-ri-arrow-right-s-line' : 'i-ri-arrow-left-s-line'"
                    class="text-xl text-charcoal-400"
                  />
                </u-tooltip>
              </button>
            </client-only>

          </div>
        </div>
      </div>
    </div>

    <client-only>
      <u-notifications>
        <template #title="{title}"><span v-html="title" /></template>
        <template #description="{description}"><span v-html="description" /></template>
      </u-notifications>
      <core-loading />
      <core-modal-modals />
      <core-offline-screen />
    </client-only>

  </div>
</template>

<script setup>
  const {lg} = useScreenSize();
  const {showAlbumsNav} = useAlbumsNav();
  const isContextNavCollapsed = ref(!lg.value);

  //CONTEXT NAV STYLING
  const contextNavClass = computed(() => {
    if (showAlbumsNav.value && isContextNavCollapsed.value) {
      return 'w-0 border-none';
    } else if (showAlbumsNav.value) {
      return 'w-80 lg:w-64 xl:w-72 border-gray-200 border-r';
    } else {
      return 'w-0 border-transparent overflow-hidden';
    }
  });

  function signUp() {
    useExternalRedirect().signUp(window.location);
  }

  function signIn() {
    useExternalRedirect().signIn(window.location);
  }
</script>
