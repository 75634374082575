<template>
  <u-avatar
    :size="size"
    :icon="COMMON_ICONS.album"
    :src="album.avatarImage"
    :ui="uiConfig"
    img-class="object-cover"
  />
</template>

<script setup>
  import merge from 'lodash.merge';

  const props = defineProps({
    album: Object,
    size: {
      type: String,
      default: 'md'
    },
    ui: Object
  });

  const rounded = computed(() => {
    switch (props.size) {
      case '2xs':
      case 'xs':
      case 'sm':
      case 'md':
        return 'rounded';
      default:
        return 'rounded-lg';
    }
  });

  const uiConfigBase = {
    background: 'bg-shell',
    icon: {base: 'text-charcoal/60 scale-125'},
    rounded: rounded.value
  };

  const uiConfig = merge(uiConfigBase, props.ui);
</script>
