<template>
  <div class="file-detail-manager">
    <client-only> <!-- note: a potential concept for file-detail SSR -->
      <file-detail v-if="file" />

      <template #fallback>
        <div class="fixed inset-0 bg-black/95 flex-center dark">
          <core-contextual-loading-box :loading-text="`Loading '${file.name}'`" />
          <p class="hidden">This is for file named <em>{{file.name}}</em></p>
        </div>
      </template>
    </client-only>
  </div>
</template>

<script setup>
  //todo: on file album/tag removal, when in the context of that same container, the staged files are not recomputed exactly correctly - bug is present in mars-ui as well

  import {storeToRefs} from 'pinia';

  const props = defineProps({
    fileId: String,
    fileContext: Object //contains contextId, contextType, shareToken, userId, etc
  });

  provide('fileContext', props.fileContext);

  const filesStore = useFilesStore();
  const {file, files: storeFiles, hasMore: storeHasMore, collectionDescriptor} = storeToRefs(filesStore);

  const fileIndexInStore = computed(() => (
    filesStore.isSameAsLoadedFilesContext(props.fileContext) || props.fileContext.contextType === FILE_CONTEXTS.file
      ? storeFiles.value?.findIndex(sf => sf.id === props.fileId)
      : -1)
  );

  const stagedFilesBounds = computed(() => (fileIndexInStore.value > -1
    ? {
        start: Math.max(fileIndexInStore.value - FILE_STAGED_FILES_BEFORE_CURRENT, 0),
        end: Math.min(fileIndexInStore.value + FILE_STAGED_FILES_AFTER_CURRENT + 1, storeFiles.value?.length)
    }
    : null
  ));

  function pageFilesIn() {
    const needMorePages = fileIndexInStore.value === -1 || (fileIndexInStore.value + FILE_STAGED_FILES_AFTER_CURRENT >= storeFiles.value?.length - 1);

    if (storeHasMore.value && needMorePages) {
      filesStore.getFiles({nextPage: true});
    }
  }

  function loadStagedFilesDetails() {
    if (fileIndexInStore.value > -1) {
      const filesToGet = storeFiles.value.slice(stagedFilesBounds.value.start, stagedFilesBounds.value.end);
      filesStore.getFileDetails({files: filesToGet, ...props.fileContext}); //note: don't await
    }
  }

  watch(fileIndexInStore, () => {
    pageFilesIn();
    loadStagedFilesDetails();

  }, {immediate: true});

  watch(storeFiles, () => {
    pageFilesIn();
  }, {immediate: true});

  //initial load
  const {data, pending, error} = await useAsyncData(
    `file-detail-${props.fileId}`,
    async () => {

      //attempt to get the file from the loaded list in the files store
      if (fileIndexInStore.value > -1) {
        loadStagedFilesDetails();

        return storeFiles.value[fileIndexInStore.value];
      }

      //file not yet loaded = just get the detail record
      const detailRecords = await filesStore.getFileDetails({files: [{id: props.fileId}], ...props.fileContext});

      return detailRecords.find(f => f.id === props.fileId);
    },
    {watch: [() => props.fileId]}
  );

  //note: in any container context when the requested file is missing we get a 400 from the API, regardless of whether the file is missing or we don't have access to it. When not in a container context the API returns a 200, so we can throw a 404 if the requested file is not returned
  //todo: see if we need to differentiate between a 404 and a 403 status with shared files

  //todo: when a single shared file is accessed, the API returns a 400 if the share token is incorrect, but 403 if the share token is missing. We might consider making that consistent

  if (error.value) {
    throw createError({
      ...error.value,
      statusCode: 404
    });
  }

  if (!data.value) {
    throw createError({
      statusCode: 404
    });
  }


  watch(data, newVal => {
    filesStore.setFile({file: newVal});
  }, {immediate: true});

  //processing check
  let processingRefreshInterval = null;

  function setupProcessingCheckRefresh({forceQuit} = {}) {
    if (file.value?.is) {

      if ((file.value.is.processing || file.value.is.faceRecProcessing) && !processingRefreshInterval) {
        processingRefreshInterval = setInterval(() => {
          const filesToGet = storeFiles.value
            .slice(stagedFilesBounds.value.start, stagedFilesBounds.value.end)
            .filter(file => file.is.processing || file.is.faceRecProcessing);

          filesStore.getFileDetails({files: filesToGet, refresh: true, ...props.fileContext});
        }, 5000);
      }

      if ((!file.value.is.processing && !file.value.is.faceRecProcessing && processingRefreshInterval) || forceQuit) {
        clearInterval(processingRefreshInterval);
        processingRefreshInterval = null;
      }
    }

  }

  watch(file, () => setupProcessingCheckRefresh());

  //meta
  const metaTitle = computed(() => {
    //determine appropriate title and description (in cse of project)
    let fileType;
    if (file.value?.is.photo) {
      fileType = 'Photo';
    } else if (file.value?.is.document) {
      fileType = 'Document';
    } else if (file.value?.is.project) {
      fileType = (file.value?.product && file.value?.product.name) || 'Project';
    } else {
      fileType = 'File';
    }

    return `${file.value?.name} | ${fileType} by ${(file.value?.user?.name) || '...'}`;
  });
  const metaDescription = computed(() => (file.value?.description || file.value?.product?.name));
  useForeverHead({
    title: metaTitle,
    description: metaDescription,
    socialImage: file.value?.images?.preview && updateQueryParameters(file.value.images.preview, {
      width: 750,
      height: 750
    })
  });

  //todo: figure this out - it is used to specify where user will be going back to from profile view
  // this.$route.meta.entityName = file.value.name;

  onMounted(() => {
    //todo: figure out
    /*if (!this.isAuthenticated && this.params.shareToken) {
      this.setSignupCookie({userId: this.params.userId});
    }*/

  });

  onUnmounted(() => setupProcessingCheckRefresh({forceQuit: true}));

</script>
