<template>
  <core-select-menu-selections v-if="showSelectionTokens" v-model:selected="selected" context-type="tag" />
  <u-select-menu
    class="tag-select-menu"
    v-model="selected"
    v-model:query="inputStr"
    :size="size"
    :leading-icon="COMMON_ICONS.tag"
    by="id"
    option-attribute="name"
    multiple
    :searchable="getTags"
    :searchable-lazy="false"
    show-create-option-when="always"
    ref="selectElement"
    :searchable-placeholder="searchablePlaceholder"
    :ui-menu="{base: 'scrollbar-light'}"
    :ui="ui"
    :popper="popper"
    @close="close"
  >
    <template #option="{option}">
      <template v-if="option.isCreate">
        <span class="flex-shrink-0">New Tag:</span>
        <tag-avatar :tag="option" size="md" />
        <span class="block break-long-string">{{inputStr}}</span>
      </template>
      <template v-else>
        <tag-avatar :tag="option" size="md" />
        <span class="break-long-string">{{option.name}}</span>
      </template>
    </template>

    <template #empty>No tags.</template>

    <template #label>
      <template v-if="selected.length">{{selected.length}} Selected</template>
      <template v-else>{{placeholderText}}</template>
    </template>

    <slot />

  </u-select-menu>
</template>

<script setup>
  const emit = defineEmits(['update:modelValue', 'close']);
  const props = defineProps({
    modelValue: Array,
    autofocus: Boolean,
    disableCreate: Boolean,
    size: {
      type: String,
      default: 'lg'
    },
    popper: Object,
    menuClass: String,
    showSelectionTokens: {
      type: Boolean,
      default: true
    },
    ui: Object
  });

  const isLoading = ref(false);
  const placeholderText = 'Select Tags';
  const selected = ref([]);
  const selectElement = ref();

  const inputStr = ref('');
  const createOption = {
    isCreate: true
  };

  //note: this construct is necessary because for some reason selecting a tag triggers a new request to the api
  const currentOptionsDescriptor = {
    search: '',
    options: null
  };

  const searchablePlaceholder = computed(() => !props.disableCreate ? 'Search or create tags (start typing)' : 'Search tags (start typing)');

  watch(inputStr, () => (isLoading.value = true));

  async function getTags(search) {
    isLoading.value = true;

    if (search !== currentOptionsDescriptor.search || !currentOptionsDescriptor.options) {
      currentOptionsDescriptor.search = search;
      const data = await useTagApi().getTags({search});
      currentOptionsDescriptor.options = data.tags.filter(t => t.name.toLowerCase().includes(search.toLowerCase()));

      if (search && !props.disableCreate && !currentOptionsDescriptor.options.find(o => o.name === inputStr.value)) {
        currentOptionsDescriptor.options.unshift(createOption)
      }
    }

    isLoading.value = false;

    return currentOptionsDescriptor.options;
  }

  function close() {
    emit('close');
  }

  watch(selected, (tags) => {
    tags.forEach(async (tag, index) => {
      if (!tag.isCreate) {
        return;
      }

      const newTag = await useTagApi().createTag({name: inputStr.value});

      //todo: this doesn't appear to clear the search input
      selectElement.value.query = '';
      selected.value.splice(index, 1, newTag);
    });

    emit('update:modelValue', tags);

  }, {deep: true});

  watch(() => props.modelValue, newValue => {
    selected.value = newValue;
  },
  {
    immediate: true
  });

  onMounted(() => {
    if (props.autofocus) {
      // - using the selectMenu ref threw an error
      // - using the native autofocus attr on the u-select worked 1 time per page refresh
      useFocus(document.querySelector('.tag-select-menu button'), {
        initialValue: true
      });
    }
  });
</script>
